import * as t from 'io-ts';
import { fromEnum } from '../responseUtil';
import { ApiActivationStatus, ApiSupplier } from './activationsApi.types';

export const ServiceProviderActivationCodec = t.intersection([
    t.type({
        activated_at: t.string,
        activation_status: fromEnum<ApiActivationStatus>('ApiActivationStatus', ApiActivationStatus),
    }),
    t.partial({
        service_provider_identification: t.string,
    }),
]);

export enum ApiTenant {
    VW_PROD = 'vw.prod',
    VW_QA = 'vw.qa',
    VW_TEST = 'vw.test',
    LSP_TEST = 'lsp.test',
}

const TenantCodec = fromEnum<ApiTenant>('ApiTenant', ApiTenant);

export const ServiceProviderCodec = t.intersection([
    t.type({
        account_id: t.string,
        supplier: fromEnum<ApiSupplier>('ApiSupplier', ApiSupplier),
        service_provider_name: t.string,
        service_provider_identification: t.string,
        duns: t.string,
        interface_access_requested: t.boolean,
        activation_requested_at: t.string,
        activation_status: fromEnum<ApiActivationStatus>('ApiActivationStatus', ApiActivationStatus),
        tenants: t.array(TenantCodec),
    }),
    t.partial({
        activated_at: t.string,
        client_id: t.string,
    }),
]);

export const ServiceProviderPageCodec = t.type({
    service_providers: t.array(ServiceProviderCodec),
});

export type ApiServiceProvider = t.TypeOf<typeof ServiceProviderCodec>;
export type ApiServiceProviderPage = t.TypeOf<typeof ServiceProviderPageCodec>;
