import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoValue } from './NoValue';
import { getShipmentTableData } from './ShipmentDetailsRows';
import { Vehicle } from './common.types';

type ShipmentDetailsTableProps = {
    vehicle: Vehicle;
    serviceKey: string | undefined;
    displayServiceKey: boolean;
    additionalRows?: ReactNode;
};

export const ShipmentDetailsTable = ({
    vehicle,
    serviceKey,
    displayServiceKey,
    additionalRows,
}: ShipmentDetailsTableProps) => {
    const shipmentTableData = getShipmentTableData(vehicle, serviceKey, displayServiceKey);
    return (
        <table className="table table-row-first-border-none table-row-last-border-none table-layout-fixed table-column-overflow-hidden">
            <tbody>
                {shipmentTableData.map(({ messageId, value }, index) => (
                    <tr key={index}>
                        <td>
                            <FormattedMessage id={messageId} />
                        </td>
                        <td>{value ?? <NoValue />}</td>
                    </tr>
                ))}
                {additionalRows}
            </tbody>
        </table>
    );
};
