import { Address } from '../../sharedComponents/common.types';
import { ApiAddress } from '../types/transportAssignment/addressAndContactApi.types';

export const mapToAddress = (apiAddress: ApiAddress): Address => {
    return {
        name: `${apiAddress.name}${
            apiAddress.additional_name_information != null ? ' ' + apiAddress.additional_name_information : ''
        }`,
        street: `${apiAddress.street}${
            apiAddress.additional_street_information != null ? ' ' + apiAddress.additional_street_information : ''
        }`,
        postalCode: apiAddress.postal_code,
        city: apiAddress.city,
        countryCode: apiAddress.country_code,
    };
};
