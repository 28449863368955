import React from 'react';

type SimpleAssetInputProps = {
    setMeansOfTransportId: (value: { id?: string; assetId?: string }) => void;
    inputProps: {
        placeholder: string;
        value: string;
        disabled: boolean;
    };
};

export const SimpleAssetInput = (props: SimpleAssetInputProps) => {
    return (
        <input
            className="form-control"
            type="text"
            disabled={props.inputProps.disabled}
            placeholder={props.inputProps.placeholder}
            value={props.inputProps.value}
            onChange={(event) => {
                props.setMeansOfTransportId({ id: event.currentTarget.value, assetId: undefined });
            }}
            onBlur={(event) => {
                props.setMeansOfTransportId({ id: event.currentTarget.value?.trim(), assetId: undefined });
            }}
        />
    );
};
