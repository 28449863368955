import { TransportAssignment } from './transportAssignment.types';
import { getByDefaultHierarchy, HierarchyCases } from './TransportAssignmentUtils';
import { sidebarDefaultStyles, SidebarExpanderStyles } from '../sharedComponents/sidebarStyle';

type TransportAssignmentSidebarExpanderStyles = SidebarExpanderStyles & {
    isOpen: boolean;
};

export const transportAssignmentSidebarDefaultStyles: TransportAssignmentSidebarExpanderStyles = {
    ...sidebarDefaultStyles,
    isOpen: true,
};

export const getTransportAssignmentSidebarExpanderStylesAndSettings = (
    transportAssignment: TransportAssignment,
): TransportAssignmentSidebarExpanderStyles => {
    const cases: HierarchyCases<TransportAssignmentSidebarExpanderStyles> = {
        archived: {
            ...transportAssignmentSidebarDefaultStyles,
            headerTextStyles: ['text-color-darkest'],
            isOpen: false,
            borderStyle: ['border-color-lighter'],
        },
        finalized: {
            ...transportAssignmentSidebarDefaultStyles,
            headerTextStyles: ['text-color-darkest'],
            isOpen: false,
            borderStyle: ['border-color-lighter'],
        },
        cancelled: {
            ...transportAssignmentSidebarDefaultStyles,
            headerTextStyles: ['text-color-danger'],
            isOpen: false,
            borderStyle: ['border-color-lighter'],
        },
        incompleteConfirmed: {
            ...transportAssignmentSidebarDefaultStyles,
            bodyBackgroundStyles: ['bg-highlight-lightest'],
            headerTextStyles: ['text-color-warning'],
        },
        completeConfirmed: {
            ...transportAssignmentSidebarDefaultStyles,
            titleIcon: ['text-size-16', 'margin-right-5', 'rioglyph', 'rioglyph-ok-sign'],
            isOpen: false,
            borderStyle: ['border-color-lighter'],
        },
        unconfirmed: {
            ...transportAssignmentSidebarDefaultStyles,
            bodyBackgroundStyles: ['bg-highlight-lightest'],
            headerTextStyles: ['text-medium text-color-highlight'],
        },
        loaded: {
            ...transportAssignmentSidebarDefaultStyles,
            titleIcon: ['text-size-16', 'margin-right-5', 'rioglyph', 'rioglyph-ok-sign'],
            isOpen: false,
            borderStyle: ['border-color-lighter'],
        },
        unloaded: {
            ...transportAssignmentSidebarDefaultStyles,
            titleIcon: ['text-size-16', 'margin-right-5', 'rioglyph', 'rioglyph-ok-sign'],
            isOpen: false,
            borderStyle: ['border-color-lighter'],
        },
    };

    return getByDefaultHierarchy(
        transportAssignment.status,
        transportAssignment.isArchived,
        transportAssignment.isFinalized,
        cases,
    );
};
