import { FormattedMessage, useIntl } from 'react-intl';
import { FeatureToggles, useFeatureToggle } from '../../../../../../configuration/featureToggle/toggleHook';
import { MeansOfTransport } from '../../../transportAssignment.types';
import { ValidationResult } from '../confirmationForm.types';
import { AutoSuggestAssetInput } from './AutoSuggestAssetInput';
import { SimpleAssetInput } from './SimpleAssetInput';
import { ValidatedFormGroup } from '../ValidatedFormGroup';

type TransportMeansIdFormFieldProps = {
    meansOfTransport: MeansOfTransport | undefined;
    setMeansOfTransportId: (value: { id?: string; assetId?: string }) => void;
    validation: ValidationResult;
    isFormDisabled: boolean;
};

export const MeansOfTransportFormInput = (props: TransportMeansIdFormFieldProps) => {
    const intl = useIntl();

    const inputProps = {
        placeholder: intl.formatMessage({
            id: 'outboundOrderBook.transportAssignments.sidebar.confirmationForm.meansOfTransportId.placeholder',
        }),
        value: props.meansOfTransport?.id ?? '',
        disabled: props.isFormDisabled,
    };

    return (
        <div className="width-50pct padding-right-5">
            <label className="display-flex flex-wrap justify-content-between height-35">
                <span>
                    <FormattedMessage id="outboundOrderBook.transportAssignments.sidebar.confirmationForm.meansOfTransportId" />
                </span>
                <span className="text-italic">
                    <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.transportMeansId.info" />
                </span>
            </label>
            <ValidatedFormGroup validation={props.validation}>
                {useFeatureToggle(FeatureToggles.TRACKING, false).value ? (
                    <AutoSuggestAssetInput
                        meansOfTransport={props.meansOfTransport}
                        setMeansOfTransport={props.setMeansOfTransportId}
                        inputProps={inputProps}
                    />
                ) : (
                    <SimpleAssetInput setMeansOfTransportId={props.setMeansOfTransportId} inputProps={inputProps} />
                )}
            </ValidatedFormGroup>
        </div>
    );
};
