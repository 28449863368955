import { camelCase } from 'lodash';
import { ModelCategory, Vehicle, VehicleConditionType, VehicleType } from './common.types';
import { FormattedMessage } from 'react-intl';
import { NoValue } from './NoValue';
import { ReactNode } from 'react';

export type ShipmentTableData = {
    messageId: string;
    value: string | undefined | ReactNode;
};

const getFormattedMessageForCategory = (category: ModelCategory) => {
    const id = `outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.model.category.${camelCase(
        category,
    )}`;
    return <FormattedMessage id={id} />;
};

const getFormattedMessageForVehicleType = (vehicleType: VehicleType) => {
    const id = `outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.vehicleType${vehicleType}`;
    return <FormattedMessage id={id} />;
};

// TODO: add more translations here or map with other
const getFormattedMessageForDeliveryCondition = (vehicleConditionType: VehicleConditionType) => {
    return (
        <FormattedMessage
            id={`outboundPortal.transportAssignments.incident.SelectTypeOption.${camelCase(vehicleConditionType)}`}
        />
    );
};

const getFormattedPriority = (hasPriority: boolean | undefined): JSX.Element | undefined => {
    if (hasPriority === undefined) {
        return undefined;
    }
    return hasPriority ? (
        <FormattedMessage id="outboundOrderBook.common.yes" />
    ) : (
        <FormattedMessage id="outboundOrderBook.common.no" />
    );
};

export const getShipmentTableData = (
    vehicle: Vehicle,
    serviceKey: string | undefined,
    displayServiceKey: boolean,
): ShipmentTableData[] => {
    const beforeServiceKey = [
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.model.modelGroup',
            value: vehicle.model.modelGroup,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.dimensions',
            value: (
                <>
                    {vehicle.dimensions?.length ?? <NoValue />} mm x {vehicle.dimensions?.width ?? <NoValue />} mm x{' '}
                    {vehicle.dimensions?.height ?? <NoValue />} mm
                </>
            ),
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.weight',
            value: vehicle.weight !== undefined ? `${vehicle.weight} kg` : undefined,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.colour',
            value: vehicle.colour,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.vehicleType',
            value: vehicle.vehicleType != null ? getFormattedMessageForVehicleType(vehicle.vehicleType) : undefined,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.licensePlate',
            value: vehicle.licensePlate,
        },
    ];

    const afterServiceKey = [
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.deliveryCondition',
            value:
                vehicle.deliveryConditions != null ? (
                    <span className="text-bold text-color-warning">
                        {getFormattedMessageForDeliveryCondition(vehicle.deliveryConditions)}
                    </span>
                ) : undefined,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.model.vehicleCategory',
            value: vehicle.model.category != null ? getFormattedMessageForCategory(vehicle.model.category) : undefined,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.model.fuelType',
            value:
                vehicle.model.fuelType != null ? (
                    <FormattedMessage
                        id={
                            'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.model.fuelType.' +
                            vehicle.model.fuelType
                        }
                    />
                ) : undefined,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.expressTransport',
            value: getFormattedPriority(vehicle.hasPriority),
        },
    ];

    const specialInstructionValues =
        vehicle.specialInstructions.length === 0 ? [undefined] : vehicle.specialInstructions;
    const specialInstructions = specialInstructionValues.map((specialInstruction) => ({
        messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.specialInstructions',
        value: specialInstruction,
    }));

    if (displayServiceKey) {
        return [
            ...beforeServiceKey,
            {
                messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.serviceKey',
                value: serviceKey,
            },
            ...afterServiceKey,
            ...specialInstructions,
        ];
    } else {
        return [...beforeServiceKey, ...afterServiceKey, ...specialInstructions];
    }
};
