import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceOrder, ServiceOrderCategory } from '../ServiceOrders.types';
import { ServiceOrderRow } from './ServiceOrdersTableRow';
import { serviceOrderColumnDefinition, ServiceOrdersTableColumns } from './ServiceOrdersTableColumns';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getTableViewType } from '../../reducers/viewSlice';
import { useGetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { ContentLoadingRow } from '../../sharedComponents/BaseTableComponents';
import { getServiceOrderCategory } from '../../reducers/serviceOrdersSlice';
import { getTableClassNames } from '../../sharedComponents/SortedTableHeader';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { ServiceOrderTableHeader } from './ServiceOrderTableHeader';

type ServiceOrderTableProps = {
    serviceOrders: ServiceOrder[];
};

export const ServiceOrderTable: FC<ServiceOrderTableProps> = ({ serviceOrders }) => {
    const viewType = useAppSelector(getTableViewType);
    const tableCategory = useAppSelector(getServiceOrderCategory);
    const isFetchingInitiatedByUser = useGetUserTriggeredFetching();

    const isArchivedTab = tableCategory === ServiceOrderCategory.ARCHIVE;

    const tableClassNames = getTableClassNames(serviceOrders, viewType);

    const colsToDisplay = serviceOrderColumnDefinition.filter(
        (def) => !isArchivedTab || def.column !== ServiceOrdersTableColumns.ACTION_BUTTON,
    );

    return (
        <table className={tableClassNames}>
            <colgroup>
                {colsToDisplay.map((def) =>
                    def.column === ServiceOrdersTableColumns.HIGHLIGHT ? (
                        <col key={def.column} className="width-40" />
                    ) : (
                        <col key={def.column} />
                    ),
                )}
            </colgroup>
            <ServiceOrderTableHeader />
            <tbody>
                {isFetchingInitiatedByUser ? (
                    <ContentLoadingRow numberOfColumns={colsToDisplay.length} />
                ) : serviceOrders.length === 0 ? (
                    <tr>
                        <td colSpan={colsToDisplay.length}>
                            <NotFoundState
                                headline={<FormattedMessage id="outboundPortal.serviceOrders.notFound" />}
                                outerClassName="border-none"
                            />
                        </td>
                    </tr>
                ) : (
                    serviceOrders.map((serviceOrder: ServiceOrder) => (
                        <ServiceOrderRow key={serviceOrder.id} serviceOrder={serviceOrder} />
                    ))
                )}
            </tbody>
        </table>
    );
};
