import Button from '@rio-cloud/rio-uikit/Button';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActivationStatus } from '../activation/activation.types';
import { ActivationRequestAcceptConfirmation } from './ActivationRequestAcceptConfirmation';
import { ActivationRequestRejection } from './ActivationRequestRejection';
import { ServiceProvider } from './serviceProvider.types';

export const ServiceProviderActions = ({ serviceProvider }: { serviceProvider: ServiceProvider }) => {
    const alreadyProcessed = serviceProvider.activationStatus !== ActivationStatus.PENDING;
    const [showAccept, setShowAccept] = useState(false);
    const [showReject, setShowReject] = useState(false);

    return (
        <span className="btn-toolbar">
            <Button
                disabled={alreadyProcessed}
                bsStyle={Button.WARNING}
                iconName="rioglyph-thumbs-down"
                onClick={() => setShowReject(true)}
            >
                <FormattedMessage id="outboundOrderBook.common.button.reject" />
            </Button>
            <Button
                disabled={alreadyProcessed}
                bsStyle={Button.PRIMARY}
                iconName="rioglyph-thumbs-up"
                onClick={() => setShowAccept(true)}
            >
                <FormattedMessage id="outboundOrderBook.common.button.accept" />
            </Button>
            <ActivationRequestAcceptConfirmation
                serviceProvider={serviceProvider}
                show={showAccept}
                setShow={setShowAccept}
            />
            <ActivationRequestRejection
                serviceProviderName={serviceProvider.serviceProviderName}
                serviceProviderAccountId={serviceProvider.accountId}
                show={showReject}
                setShow={setShowReject}
            />
        </span>
    );
};
