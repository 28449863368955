import { useFeatureFlag, User } from 'configcat-react';
import { useAppSelector } from '../setup/hooks';
import { getUserAccount } from '../login/loginSlice';

export const FeatureToggles = {
    TRACKING: 'tracking',
    LSP_ADMINISTRATION: 'lspAdministration',
} as const;

type FeatureTogglesT = (typeof FeatureToggles)[keyof typeof FeatureToggles];

export const MOCK_FT_VALUES: Record<FeatureTogglesT, DefaultValue> = {
    tracking: true,
    lspAdministration: true,
};

type DefaultValue = string | boolean | number;
type DefaultValueTypeOf<T> = T extends boolean ? boolean : T extends number ? number : T extends string ? string : T;

export const useFeatureToggle = <V extends DefaultValue>(
    featureToggleId: FeatureTogglesT,
    defaultValue: V = false as V,
): { loading: boolean; value: DefaultValueTypeOf<V> } => {
    const account = useAppSelector(getUserAccount);
    const userObject = new User(account ?? 'NO_IDENTIFIER');
    const result = useFeatureFlag(featureToggleId, defaultValue, userObject);

    if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
        return { value: MOCK_FT_VALUES[featureToggleId] as DefaultValueTypeOf<V>, loading: false };
    } else {
        return result;
    }
};
