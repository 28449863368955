import { CompoundTransportOrder, CompoundTransportOrderStatus } from '../compoundTransportOrder.types';
import { ShipmentDetailsTable } from '../../sharedComponents/ShipmentDetailsTable';
import { Model } from '../../sharedComponents/common.types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type ShipmentDetailsProps = {
    compoundTransportOrder: CompoundTransportOrder;
};

export const ShipmentDetails = ({ compoundTransportOrder }: ShipmentDetailsProps) => {
    const isCancelled = compoundTransportOrder.status === CompoundTransportOrderStatus.CANCELLED;
    const { model } = compoundTransportOrder.vehicle;

    return (
        <ul className="list-group margin-20">
            <li className="list-group-item">
                <ShipmentDetailsHeader model={model} isCancelled={isCancelled} />
            </li>
            <li className="list-group-item">
                <ShipmentDetailsTable
                    vehicle={compoundTransportOrder.vehicle}
                    serviceKey={undefined}
                    displayServiceKey={false}
                />
            </li>
        </ul>
    );
};

type ShipmentDetailsHeaderProps = {
    model: Model;
    isCancelled: boolean;
};

export const ShipmentDetailsHeader = ({ model, isCancelled }: ShipmentDetailsHeaderProps) => {
    return (
        <>
            <label>
                <FormattedMessage id="outboundOrderBook.common.heading.model" />
            </label>
            <div
                className={classNames('flex-1-1 text-normal ellipsis-3', {
                    'text-decoration-line-through': isCancelled,
                })}
            >
                <span className="rioglyph rioglyph-car text-size-18 margin-right-5" aria-hidden="true" />
                &nbsp;
                {model.name.replace(/\s+/g, ' ')}
            </div>
        </>
    );
};
