import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes, TRANSPORT_ORDER_ROUTE, WELCOME_ROUTE } from './routes';

export const DefaultRedirect = (props: { isActive: boolean }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.isActive) {
            navigate(WELCOME_ROUTE);
        } else if (!routes.includes(pathname)) {
            navigate(TRANSPORT_ORDER_ROUTE);
        }
    }, [pathname, navigate, props.isActive]);

    return null;
};
