import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../responseUtil';

export const TermsAndConditionsAgreementCodec = t.type({
    accepted: t.boolean,
});

export enum ApiSupplier {
    VW = 'vw',
}

export enum ApiActivationStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
}

const SupplierCodec = fromEnum<ApiSupplier>('ApiSupplier', ApiSupplier);
const ActivationStatusCodec = fromEnum<ApiActivationStatus>('ApiActivationStatus', ApiActivationStatus);

export const ActivationCodec = t.type({
    supplier: SupplierCodec,
    status: ActivationStatusCodec,
});

export const ActivationStatusesCodec = t.type({
    activations: t.array(ActivationCodec),
});

const ActivationRequestCodec = t.intersection([
    t.type({
        supplier: SupplierCodec,
        service_provider_identification: NonEmptyString,
        duns: NonEmptyString,
        interface_access_requested: t.boolean,
    }),
    t.partial({
        phone: NonEmptyString,
    }),
]);

export type ApiActivationRequest = t.TypeOf<typeof ActivationRequestCodec>;
export type ApiTermsAndConditionsAgreement = t.TypeOf<typeof TermsAndConditionsAgreementCodec>;
export type ApiActivationStatuses = t.TypeOf<typeof ActivationStatusesCodec>;
export type ApiActivation = t.TypeOf<typeof ActivationCodec>;
