import { ColumnDefinition } from '../../sharedComponents/SortedTableHeader';

export enum TableIncomingCompoundTransportOrderColumn {
    VIN = 'vin',
    EXPECTED_DELIVERY_AT = 'expectedDeliveryAt',
    STATUS = 'status',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    CARRIER = 'carrier',
    MEANS_OF_TRANSPORT = 'meansOfTransport',
}

export const incomingCompoundTransportOrderDefinition: ColumnDefinition<TableIncomingCompoundTransportOrderColumn>[] = [
    {
        column: TableIncomingCompoundTransportOrderColumn.VIN,
        labelId: 'outboundOrderBook.common.heading.vin',
        sortable: false,
    },
    {
        column: TableIncomingCompoundTransportOrderColumn.EXPECTED_DELIVERY_AT,
        labelId: 'outboundOrderBook.CompoundTransportOrder.eta',
        sortable: true,
    },
    {
        column: TableIncomingCompoundTransportOrderColumn.STATUS,
        labelId: 'outboundOrderBook.common.heading.status',
        sortable: false,
    },
    {
        column: TableIncomingCompoundTransportOrderColumn.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    {
        column: TableIncomingCompoundTransportOrderColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.model',
        sortable: false,
    },
    {
        column: TableIncomingCompoundTransportOrderColumn.CARRIER,
        labelId: 'outboundOrderBook.compoundTransportOrder.table.heading.carrier',
        sortable: true,
    },
    {
        column: TableIncomingCompoundTransportOrderColumn.MEANS_OF_TRANSPORT,
        labelId: 'outboundOrderBook.common.heading.meansOfTransport',
        sortable: false,
    },
];
