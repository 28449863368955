import { CompoundTransportOrderStatus } from '../../compoundTransportOrder.types';
import {
    getIncomingCompoundTransportOrdersStatusFilter,
    incomingCompoundTransportOrdersSlice,
} from '../../../reducers/incomingCompoundTransportOrdersSlice';
import { StatusFilter } from '../../../sharedComponents/StatusFilter';

export const IncomingCompoundTransportOrderStatusFilter = () => {
    const additionalOptionClasses = new Map([
        [CompoundTransportOrderStatus.CANCELLED, 'bg-white'],
        [CompoundTransportOrderStatus.CONFIRMED, 'bg-white'],
        [CompoundTransportOrderStatus.UNCONFIRMED, 'bg-white'],
        [CompoundTransportOrderStatus.LOADED, 'custom-label-border-color-white'],
    ]);

    return (
        <StatusFilter
            getFilterSelector={getIncomingCompoundTransportOrdersStatusFilter}
            setFilterAction={incomingCompoundTransportOrdersSlice.actions.setStatusFilter}
            statusOptions={Object.values(CompoundTransportOrderStatus)}
            optionClassName={additionalOptionClasses}
        />
    );
};
