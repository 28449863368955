import { incomingCompoundTransportOrderDefinition } from './IncomingCompoundTransportOrderColumns';
import { CompoundTransportOrder } from '../compoundTransportOrder.types';
import * as React from 'react';
import { FC } from 'react';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { FormattedMessage } from 'react-intl';
import { ContentLoadingRow } from '../../sharedComponents/BaseTableComponents';
import { useGetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getTableViewType } from '../../reducers/viewSlice';
import { IncomingCompoundTransportOrderTableRow } from './IncomingCompoundTransportOrderTableRow';
import { getTableClassNames } from '../../sharedComponents/SortedTableHeader';
import { IncomingCompoundTransportOrderHeader } from './IncomingCompoundTransportOrderHeader';

type IncomingCompoundTransportOrderTableProps = {
    compoundTransportOrders: CompoundTransportOrder[];
};

export const IncomingCompoundTransportOrderTable: FC<IncomingCompoundTransportOrderTableProps> = ({
    compoundTransportOrders,
}) => {
    const viewType = useAppSelector(getTableViewType);
    const isFetchingInitiatedByUser = useGetUserTriggeredFetching();

    const tableClassNames = getTableClassNames(compoundTransportOrders, viewType);

    return (
        <table className={tableClassNames}>
            <IncomingCompoundTransportOrderHeader />
            <tbody>
                {isFetchingInitiatedByUser ? (
                    <ContentLoadingRow numberOfColumns={incomingCompoundTransportOrderDefinition.length} />
                ) : compoundTransportOrders.length === 0 ? (
                    <tr>
                        <td colSpan={incomingCompoundTransportOrderDefinition.length}>
                            <NotFoundState
                                headline={<FormattedMessage id="outboundOrderBook.compoundTransportOrder.notFound" />}
                                outerClassName="border-none"
                            />
                        </td>
                    </tr>
                ) : (
                    compoundTransportOrders.map((compoundTransportOrder: CompoundTransportOrder) => (
                        <IncomingCompoundTransportOrderTableRow
                            key={compoundTransportOrder.id}
                            compoundTransportOrder={compoundTransportOrder}
                        />
                    ))
                )}
            </tbody>
        </table>
    );
};
