import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { DateTime } from '../../sharedComponents/BaseTableComponents';
import { NoValue } from '../../sharedComponents/NoValue';
import { Address } from '../../sharedComponents/common.types';
import { TransportCapacityOrder, TransportOrderBundleDeliveries } from '../transportAssignment.types';

export const AddressField = (props: { address: Address }) => (
    <div>
        <span className="text-medium ellipsis-2">{`${props.address.name} `}</span>
        <span>{`${props.address.postalCode} ${props.address.city}`}</span>
    </div>
);

export const IconCell = (props: { icon: string; formattedMessageId: string; nr: number }) => (
    <>
        <span className={classNames('rioglyph', 'text-size-18', 'padding-right-5', props.icon)} />
        <span className="text-bold">{props.nr === 0 ? <>&mdash;&nbsp;</> : props.nr.toString(10) + ' '}</span>
        <FormattedMessage id={props.formattedMessageId} />
    </>
);

const getVehicleModelNames = (transportOrderBundleDeliveries: TransportOrderBundleDeliveries[]): [string, boolean][] =>
    transportOrderBundleDeliveries.flatMap((delivery) => {
        return delivery.originatedTransportOrders.map<[string, boolean]>((originatedTransportOrder) => [
            originatedTransportOrder.vehicle.model.name.trim(),
            originatedTransportOrder.isCancelled,
        ]);
    });

const getVehicleModelGroups = (transportOrderBundleDeliveries: TransportOrderBundleDeliveries[]): [string, boolean][] =>
    transportOrderBundleDeliveries.flatMap((delivery) => {
        return delivery.originatedTransportOrders.map<[string, boolean]>((originatedTransportOrder) => [
            originatedTransportOrder.vehicle.model.modelGroup.trim(),
            originatedTransportOrder.isCancelled,
        ]);
    });

export const TransportOrderBundleVehiclesCell = (props: {
    transportOrderBundleDeliveries: TransportOrderBundleDeliveries[];
}) => {
    const vehicleModelNames = getVehicleModelNames(props.transportOrderBundleDeliveries);
    const notCancelledVehicleModelNames = vehicleModelNames
        .filter(([_, isCancelled]) => !isCancelled)
        .map(([modelName, _]) => modelName);

    if (vehicleModelNames.length === 0) {
        return <NoValue />;
    } else if (vehicleModelNames.length === 1) {
        return (
            <div
                className={classNames('ellipsis-1', {
                    'text-decoration-line-through': vehicleModelNames[0][1],
                })}
            >
                {vehicleModelNames[0][0]}
            </div>
        );
    } else if (notCancelledVehicleModelNames.length === 1) {
        return <div>{notCancelledVehicleModelNames[0]}</div>;
    } else {
        const listOfVehicles = notCancelledVehicleModelNames.map((modelName, idx) => <p key={idx}>{modelName}</p>);
        return (
            <OverlayTrigger
                placement="top-start"
                overlay={
                    listOfVehicles.length > 0 ? (
                        <Tooltip textAlignment="left" width="auto">
                            {listOfVehicles}
                        </Tooltip>
                    ) : (
                        <div />
                    )
                }
            >
                <div
                    className={classNames({
                        'text-decoration-line-through': notCancelledVehicleModelNames.length === 0,
                    })}
                >
                    <IconCell
                        icon="rioglyph-car"
                        nr={
                            notCancelledVehicleModelNames.length === 0
                                ? vehicleModelNames.length
                                : notCancelledVehicleModelNames.length
                        }
                        formattedMessageId="outboundPortal.transportAssignments.table.vehicles"
                    />
                </div>
            </OverlayTrigger>
        );
    }
};

export const TransportOrderCapacityOrderVehiclesCell = (props: { transportCapacityOrder: TransportCapacityOrder }) => {
    const numberOfVehicles = props.transportCapacityOrder.requestedTransportCapacity;

    if (numberOfVehicles === 0) {
        return <NoValue />;
    } else {
        const name =
            numberOfVehicles === 1
                ? 'outboundPortal.transportAssignments.table.vehicle'
                : 'outboundPortal.transportAssignments.table.vehicles';
        return (
            <div>
                <IconCell icon="rioglyph-group" nr={numberOfVehicles} formattedMessageId={name} />
            </div>
        );
    }
};

export const TransportOrderBundleModelGroupCell = (props: {
    transportOrderBundleDeliveries: TransportOrderBundleDeliveries[];
}) => {
    const { transportOrderBundleDeliveries } = props;

    const modelGroupNamesAndCancelledStatus = getVehicleModelGroups(transportOrderBundleDeliveries);
    const distinctModelGroups = new Set(modelGroupNamesAndCancelledStatus.map(([modelGroup, _]) => modelGroup));
    const distinctNotCancelledModelGroups = new Set(
        modelGroupNamesAndCancelledStatus
            .filter(([_, isCancelled]) => !isCancelled)
            .map(([modelGroup, _]) => modelGroup),
    );

    if (modelGroupNamesAndCancelledStatus.length === 0) {
        return <NoValue />;
    } else if (distinctModelGroups.size === 1) {
        return (
            <div
                className={classNames({
                    'text-decoration-line-through': distinctNotCancelledModelGroups.size === 0,
                })}
            >
                {Array.from(distinctModelGroups)[0]}
            </div>
        );
    } else {
        const listOfModelGroups = Array.from(distinctNotCancelledModelGroups).map((line: string, idx) => (
            <p key={idx}>{line}</p>
        ));

        return (
            <OverlayTrigger
                placement="bottom-start"
                overlay={
                    listOfModelGroups.length > 0 ? (
                        <Tooltip textAlignment="left" width="auto">
                            {listOfModelGroups}
                        </Tooltip>
                    ) : (
                        <div />
                    )
                }
            >
                <div
                    className={classNames({
                        'text-decoration-line-through': distinctNotCancelledModelGroups.size === 0,
                    })}
                >
                    <IconCell
                        icon="rioglyph-car"
                        nr={
                            distinctNotCancelledModelGroups.size === 0
                                ? distinctModelGroups.size
                                : distinctNotCancelledModelGroups.size
                        }
                        formattedMessageId="outboundPortal.transportAssignments.table.models"
                    />
                </div>
            </OverlayTrigger>
        );
    }
};

export const UnloadingAddress = (props: { address?: Address; hasSelectableUnloadingAddress: boolean }) => {
    const { address, hasSelectableUnloadingAddress } = props;
    if (address === undefined) {
        return (
            <NoValue
                messageId={
                    hasSelectableUnloadingAddress
                        ? 'outboundPortal.transportAssignments.table.to.enterDestination'
                        : undefined
                }
            />
        );
    }
    return <AddressField address={address} />;
};

export const TransportOrderBundleUnloading = (props: {
    transportOrderBundleDeliveries: TransportOrderBundleDeliveries[];
}) => {
    if (props.transportOrderBundleDeliveries.length > 1) {
        const listOfAddresses = props.transportOrderBundleDeliveries.map((delivery, idx) => {
            const address = delivery.unloading.addressAndContact?.address;
            if (address !== undefined) {
                return (
                    <p key={idx}>
                        <span>{address.name}</span>
                        <br />
                        <span>{`${address.postalCode} ${address.city}`}</span>
                    </p>
                );
            }
        });
        return (
            <OverlayTrigger
                placement="top-start"
                overlay={
                    listOfAddresses.length > 0 ? (
                        <Tooltip textAlignment="left" width="auto">
                            {listOfAddresses}
                        </Tooltip>
                    ) : (
                        <div />
                    )
                }
            >
                <div>
                    <IconCell
                        icon="rioglyph-map-marker"
                        nr={props.transportOrderBundleDeliveries.length}
                        formattedMessageId="outboundPortal.transportAssignments.table.addresses"
                    />
                </div>
            </OverlayTrigger>
        );
    } else {
        const address = props.transportOrderBundleDeliveries[0]?.unloading.addressAndContact?.address;
        if (address === undefined) {
            return <NoValue />;
        }
        return <AddressField address={address} />;
    }
};

export const TransportOrderBundleDeliveryDate = (props: {
    transportOrderBundleDeliveries: TransportOrderBundleDeliveries[];
}) => {
    if (props.transportOrderBundleDeliveries.length > 1) {
        const listOfDates = props.transportOrderBundleDeliveries.map((delivery, idx) => {
            return (
                <p key={idx}>
                    <DateTime
                        dateTime={delivery.unloading.requestedDeliveryDate}
                        timeZone={delivery.unloading.timeZone}
                    />
                </p>
            );
        });

        return (
            <OverlayTrigger
                placement="bottom-start"
                overlay={
                    <Tooltip textAlignment="left" width="auto">
                        {listOfDates}
                    </Tooltip>
                }
            >
                <div>
                    <IconCell
                        icon="rioglyph-calendar"
                        nr={props.transportOrderBundleDeliveries.length}
                        formattedMessageId="outboundPortal.transportAssignments.table.dates"
                    />
                </div>
            </OverlayTrigger>
        );
    } else {
        return (
            <DateTime
                dateTime={props.transportOrderBundleDeliveries[0].unloading.requestedDeliveryDate}
                timeZone={props.transportOrderBundleDeliveries[0].unloading.timeZone}
            />
        );
    }
};
