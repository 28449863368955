import { ApiServiceProvider, ApiServiceProviderPage, ApiTenant } from '../types/serviceProviderApi.types';
import { ServiceProvider, ServiceProviderActivation, Tenant } from '../../serviceProviders/serviceProvider.types';
import { parseStringDate } from '../../sharedComponents/dateHelper';
import { mapToActivationStatus, mapToSupplier } from './activationMapper';
import { ActivationStatus } from '../../activation/activation.types';
import { ApiActivationStatus } from '../types/activationsApi.types';

export const mapToApiActivationStatus = (activationStatus: ActivationStatus): ApiActivationStatus => {
    switch (activationStatus) {
        case ActivationStatus.ACTIVE:
            return ApiActivationStatus.ACTIVE;
        case ActivationStatus.PENDING:
            return ApiActivationStatus.PENDING;
    }
};

export const mapToApiServiceProviderActivation = (serviceProviderActivation: ServiceProviderActivation) => {
    return {
        activated_at: serviceProviderActivation.activatedAt.toISOString(),
        activation_status: mapToApiActivationStatus(serviceProviderActivation.activationStatus),
        service_provider_identification: serviceProviderActivation.serviceProviderId,
    };
};

export const mapToTenant = (apiTenant: ApiTenant): Tenant => {
    switch (apiTenant) {
        case ApiTenant.LSP_TEST:
            return Tenant.LSP_TEST;
        case ApiTenant.VW_PROD:
            return Tenant.VW_PROD;
        case ApiTenant.VW_QA:
            return Tenant.VW_QA;
        case ApiTenant.VW_TEST:
            return Tenant.VW_TEST;
    }
};

export const mapToServiceProvider = (apiServiceProvider: ApiServiceProvider): ServiceProvider => {
    return {
        accountId: apiServiceProvider.account_id,
        supplier: mapToSupplier(apiServiceProvider.supplier),
        serviceProviderName: apiServiceProvider.service_provider_name,
        serviceProviderId: apiServiceProvider.service_provider_identification,
        duns: apiServiceProvider.duns,
        registrationDate: parseStringDate(apiServiceProvider.account_id, apiServiceProvider.activation_requested_at),
        activationDate:
            apiServiceProvider.activated_at !== undefined
                ? parseStringDate(apiServiceProvider.account_id, apiServiceProvider.activated_at)
                : undefined,
        apiAccessRequested: apiServiceProvider.interface_access_requested,
        activationStatus: mapToActivationStatus(apiServiceProvider.activation_status),
        tenants: apiServiceProvider.tenants.map((tenant) => mapToTenant(tenant)),
        clientId: apiServiceProvider.client_id,
    };
};

export const mapToServiceProviders = (apiServiceProviderPage: ApiServiceProviderPage): ServiceProvider[] => {
    return apiServiceProviderPage.service_providers.map(
        (apiServiceProvider: ApiServiceProvider): ServiceProvider => mapToServiceProvider(apiServiceProvider),
    );
};
