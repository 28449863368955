import { LogLevel } from 'configcat-react';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        FINISHED_VEHICLES_SERVICE: string | undefined;
        ACCOUNTS_DETAILS_SERVICE: string | undefined;
        ASSET_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
    application: {
        name: string | undefined;
    };
    configCat: {
        apiKey: string;
        logLevel: LogLevel;
        pollingFrequencyInSeconds: number;
    };
}

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        FINISHED_VEHICLES_SERVICE: import.meta.env.VITE_FINISHED_VEHICLES_SERVICE,
        ACCOUNTS_DETAILS_SERVICE: import.meta.env.VITE_ACCOUNTS_DETAILS_SERVICE,
        ASSET_SERVICE: import.meta.env.VITE_ASSETS_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_CLIENT_ID,
        oauthScope: ['openid', 'profile', 'email', 'phone', 'menu.read'],
        mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'outbound-portal',
    application: {
        name: 'Outbound order book',
    },
    configCat: {
        // dummy key respecting key format
        apiKey: import.meta.env.VITE_CONFIG_CAT_API_KEY ?? 'JWORWAaeSeVsRucIDmGHcR/izVsBz0lnGSOXiA-IdQAYv',
        logLevel: import.meta.env.VITE_CONFIG_CAT_LOG_LEVEL === 'INFO' ? LogLevel.Info : LogLevel.Off,
        pollingFrequencyInSeconds: 600,
    },
};
