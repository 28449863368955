export type TermsAndConditionsAgreement = {
    readonly accepted: boolean;
};

export enum Supplier {
    VW = 'vw',
}

export enum ActivationStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
}

export type Activation = {
    readonly supplier: Supplier;
    readonly status: ActivationStatus;
};
