import { Interval, isDate, isValid, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

type FormattableDate = Date | string | number;

const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
const DATE_FORMAT = 'dd.MM.yyyy';
const TIME_FORMAT = 'HH:mm';

export const parseStringDate = (orderId: string, ISODateString: string): Date => {
    const date = parseISO(ISODateString);
    if (isValid(date)) {
        return date;
    } else {
        throw new Error(`Unexpected date format for order '${orderId}': ${ISODateString}`);
    }
};

export const formatDateTimeInTimeZone = (dateTime: FormattableDate, timeZone: string): string => {
    return formatInTimeZone(dateTime, timeZone, DATE_TIME_FORMAT);
};

export const formatDateInTimeZone = (dateTime: FormattableDate, timeZone: string): string => {
    return formatInTimeZone(dateTime, timeZone, DATE_FORMAT);
};

export const formatTimeInTimeZone = (dateTime: FormattableDate, timeZone: string): string => {
    return formatInTimeZone(dateTime, timeZone, TIME_FORMAT);
};

export const formatIntervalTimeZone = (interval: Interval, timeZone: string): string => {
    const startTz = formatTimeZone(interval.start, timeZone);
    const endTz = formatTimeZone(interval.end, timeZone);
    if (startTz !== endTz) {
        return `${startTz}/${endTz}`;
    } else {
        return startTz;
    }
};

export const formatTimeZone = (date: FormattableDate, timeZone: string): string => {
    return formatInTimeZone(date, timeZone, 'OOO');
};

export const isValidDate = (value: any): value is Date => isDate(value) && isValid(value);

export const isDateStringEmpty = (date: string | Date | undefined): boolean => {
    return date === undefined || date.toString().length === 0;
};

export const dateMinusSeconds = (date: Date, seconds: number): Date => new Date(date.getTime() - seconds * 1000);
