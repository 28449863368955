import * as t from 'io-ts';
import { fromEnum } from '../../responseUtil';

export enum ApiMeansOfTransportType {
    TRUCK = 'truck',
}

export enum ApiModeOfTransport {
    ROAD_TRANSPORT = 'road-transport',
}

const MeansOfTransportTypeCodec = fromEnum<ApiMeansOfTransportType>('ApiMeansOfTransportType', ApiMeansOfTransportType);

export const ModeOfTransportCodec = fromEnum<ApiModeOfTransport>('ApiModeOfTransport', ApiModeOfTransport);

export const MeansOfTransportCodec = t.intersection([
    t.type({
        type: MeansOfTransportTypeCodec,
    }),
    t.partial({
        id: t.string,
        asset_id: t.string,
    }),
]);

export type ApiMeansOfTransport = t.TypeOf<typeof MeansOfTransportCodec>;
