import { configureStore } from '@reduxjs/toolkit';

import { configReducer } from './configSlice';
import { loginReducer } from '../login/loginSlice';
import { langReducer } from '../lang/langSlice';
import { tokenReducer } from '../tokenHandling/tokenSlice';
import { appReducer } from '../../features/app/appSlice';
import { portalReducer } from '../../features/app/reducers/portalReducer';
import { outboundApi } from '../../features/app/api/outboundApi';
import { accountsApi } from '../../features/app/api/accountAPI';
import { serviceOrderApi } from '../../features/app/api/serviceOrdersApi';
import { assetsApi } from '../../features/app/api/assetsApi';
import { compoundTransportOrderApi } from '../../features/app/api/compoundTransportOrderApi';
import { serviceProviderApi } from '../../features/app/api/serviceProviderApi';

// add new reducers also to testUtils.tsx
export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        portal: portalReducer,
        // Add the generated reducer as a specific top-level slice
        [outboundApi.reducerPath]: outboundApi.reducer,
        [accountsApi.reducerPath]: accountsApi.reducer,
        [assetsApi.reducerPath]: assetsApi.reducer,
        [serviceOrderApi.reducerPath]: serviceOrderApi.reducer,
        [compoundTransportOrderApi.reducerPath]: compoundTransportOrderApi.reducer,
        [serviceProviderApi.reducerPath]: serviceProviderApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            outboundApi.middleware,
            accountsApi.middleware,
            assetsApi.middleware,
            serviceOrderApi.middleware,
            compoundTransportOrderApi.middleware,
            serviceProviderApi.middleware,
        ),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
