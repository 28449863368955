import TableCardsSorting from '@rio-cloud/rio-uikit/lib/es/TableCardsSorting';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { getTableViewType } from '../../reducers/viewSlice';
import { SortDirection, TableSort } from '../../sharedComponents/SortedTableHeader';
import { transportAssignmentColumnDefinition, TransportAssignmentTableColumn } from './TransportAssignmentTableColumns';
import { TableCategory } from './toolbar/CategoriesSelection';

export const TransportAssignmentsCardsSorting = (props: {
    sorting: TableSort<TransportAssignmentTableColumn>;
    category: TableCategory;
}) => {
    const intl = useIntl();
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();

    const cardSortingSelectOptions = transportAssignmentColumnDefinition
        .filter((def) => def.sortable)
        .map((def) => {
            return {
                id: def.column,
                label: def.labelId !== undefined ? intl.formatMessage({ id: def.labelId }) : '',
                selected: def.column === props.sorting.column,
                disabled: false,
            };
        });

    const handleCardSortChange = (sortByColumn: TransportAssignmentTableColumn, sortDir: SortDirection) =>
        dispatch(
            transportAssignmentsSlice.actions.setSortingForCategory({
                category: props.category,
                sort: { column: sortByColumn, dir: sortDir },
            }),
        );

    return viewType === TableViewToggles.VIEW_TYPE_TABLE ? (
        <></>
    ) : (
        <TableCardsSorting
            selectOptions={cardSortingSelectOptions}
            sortName={props.sorting.column}
            sortOrder={props.sorting.dir}
            onSortChange={handleCardSortChange}
        />
    );
};
