/* eslint-disable camelcase, no-console */
import {
    InMemoryWebStorage,
    User,
    UserManager,
    UserManagerSettings,
    UserProfile as Profile,
    WebStorageStateStore,
} from 'oidc-client-ts';
import join from 'lodash/fp/join';

import { mapUserProfile } from './userProfile';
import { config } from '../../config';
import { OAuthConfig } from '..';
import { AccessToken } from '../tokenHandling/tokenSlice';
import { UserProfile } from './loginSlice';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error: Error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export type SessionRenewedResult = {
    accessToken: AccessToken;
    idToken: Profile;
    profile: UserProfile;
    locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings: UserManagerSettings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        client_secret: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: 'code',
        scope: join(' ', config.login.oauthScope),
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        monitorSession: true,
        staleStateAgeInSeconds: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded((user) => {
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(() => {
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

const getProfile = (capabilityMonitoringAccessToken: string | null) => {
    if (capabilityMonitoringAccessToken != null) {
        const tokenData = parseJwt(capabilityMonitoringAccessToken);
        return {
            iss: tokenData.iss,
            aud: `Audience(s): ${tokenData.client_id}`,
            exp: tokenData.exp,
            iat: tokenData.iat,
            account: tokenData.account,
            azp: tokenData.azp,
            email: 'test@example.com',
            family_name: 'Client',
            given_name: 'Test',
            name: 'Test Client',
            sub: tokenData.sub,
            locale: config.login.mockLocale,
            tenant: tokenData.tenant,
        };
    }

    return {
        iss: 'Issuer Identifier',
        aud: 'Audience(s): client_id',
        exp: 10,
        iat: 5,
        account: 'mockaccount',
        azp: 'test-client',
        email: 'test@example.com',
        family_name: 'Client',
        given_name: 'Test',
        name: 'Test Client',
        sub: 'prod-rio-users:mock-user',
        locale: config.login.mockLocale,
        tenant: 'mock-tenant',
    };
};

export const configureMockUserManager = (
    oauthConfig: OAuthConfig,
    capabilityMonitoringAccessToken: string | null,
): UserManager => {
    const signinSilent = () => {
        const userSettings: ConstructorParameters<typeof User>[0] = {
            access_token: capabilityMonitoringAccessToken ?? 'valid-mocked-oauth-bogus-token',
            profile: getProfile(capabilityMonitoringAccessToken),
            id_token: 'id_token',
            session_state: 'session_state',
            refresh_token: 'refresh_token',
            token_type: 'token_type',
            scope: 'scope',
            expires_at: 100000,
            userState: 'state',
        };

        const user = new User(userSettings);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
        return Promise.resolve(user);
    };

    const clearStaleState = () => {
        console.info('[configuration/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState } as UserManager;
};
