/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

if (import.meta.env.MODE === 'production') {
    const release = import.meta.env.APP_VERSION;

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        environment: 'production',
        release,
        beforeSend: (event, hint) => {
            if (
                isFailedToFetchError(hint.originalException) ||
                isNetworkError(hint.originalException) ||
                isUnhandledRejectionOfNonObject(event)
            ) {
                // drop event
                return null;
            } else {
                return event;
            }
        },
    });
}

export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};

const isFailedToFetchError = (error: any): boolean =>
    typeof error === 'object' && typeof error?.error === 'string' && error.error.includes('TypeError: Failed to fetch');

const isNetworkError = (error: any): boolean =>
    typeof error === 'object' && typeof error?.error === 'string' && error.error.includes('TypeError: NetworkError');

const isUnhandledRejectionOfNonObject = (event: Sentry.ErrorEvent): boolean => {
    /* Outlook link scanning can lead to rejected promises with string
     * error instead of a properError object. See also
     * https://github.com/getsentry/sentry-javascript/issues/3440 */
    const pattern = /Non-Error promise rejection captured with value: Object Not Found Matching Id/;
    if (event?.exception?.values?.length === 1) {
        const firstException = event.exception.values[0];
        return Boolean(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            firstException.type === 'UnhandledRejection' && firstException.value && pattern.test(firstException.value),
        );
    } else {
        return false;
    }
};
