export const TRANSPORT_ORDER_ROUTE = '/transport-orders';
export const SERVICE_ORDER_ROUTE = '/service-orders';
export const INCOMING_COMPOUND_TRANSPORT_ORDER_ROUTE = '/incoming-vehicles';
export const WELCOME_ROUTE = '/welcome';
export const SERVICE_PROVIDER_ACTIVATION_ROUTE = '/service-providers';
export const routes = [
    WELCOME_ROUTE,
    TRANSPORT_ORDER_ROUTE,
    SERVICE_ORDER_ROUTE,
    INCOMING_COMPOUND_TRANSPORT_ORDER_ROUTE,
    SERVICE_PROVIDER_ACTIVATION_ROUTE,
];
