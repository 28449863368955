import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import { Interval } from 'date-fns';
import { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { LabelWithTimezone } from '../../../sharedComponents/LabelWithTimezone';
import { formatDateTimeInTimeZone, isValidDate } from '../../../sharedComponents/dateHelper';
import { DatePickerType } from '../TransportAssignmentConfirmationForm';
import { ValidationResult } from './confirmationForm.types';
import { ValidatedFormGroup } from './ValidatedFormGroup';

const dateTimeIntervalToString = (date: Interval, timeZone: string): string => {
    const startString = formatDateTimeInTimeZone(date.start, timeZone);
    const endString = formatDateTimeInTimeZone(date.end, timeZone);

    if (startString === endString) {
        return startString;
    }

    return `${startString} - ${endString}`;
};

type DatePickerFormGroupPropsBase = {
    displayFieldId: string;
    displayFieldMessageId: string;
    inputFieldMessageId: string;
    inputFieldId: string;
    inputFieldValue: string | Date | undefined;
    timeZone: string;
    onChange: (value: Moment | string) => void;
    validation: ValidationResult;
    isFormDisabled: boolean;
};

type DatePickerFormGroupProps =
    | (DatePickerFormGroupPropsBase & {
          datePickerType: DatePickerType.PICKUP_DATE_PICKER;
          displayFieldValue: Interval | undefined;
      })
    | (DatePickerFormGroupPropsBase & {
          datePickerType: DatePickerType.DELIVERY_DATE_PICKER;
          displayFieldValue: Date | undefined;
      });

export const DatePickerFormGroup = (props: DatePickerFormGroupProps) => {
    const intl = useIntl();
    const getDisplayValue = () => {
        if (props.displayFieldValue === undefined) {
            return '';
        }
        switch (props.datePickerType) {
            case DatePickerType.PICKUP_DATE_PICKER:
                return dateTimeIntervalToString(props.displayFieldValue, props.timeZone);
            case DatePickerType.DELIVERY_DATE_PICKER:
                return formatDateTimeInTimeZone(props.displayFieldValue, props.timeZone);
        }
    };

    return (
        <div className="display-flex align-items-start">
            <div className="width-50pct padding-right-5">
                <LabelWithTimezone
                    htmlFor={props.displayFieldId}
                    messageId={props.displayFieldMessageId}
                    timeZoneData={{ timeZone: props.timeZone, date: props.displayFieldValue }}
                />
                <div className="input-group">
                    <input
                        id={props.displayFieldId}
                        className="form-control"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'outboundOrderBook.common.noData' })}
                        disabled
                        value={getDisplayValue()}
                    />
                </div>
            </div>
            <ValidatedFormGroup validation={props.validation} additionalClassNames="width-50pct padding-left-5">
                <LabelWithTimezone
                    htmlFor={props.inputFieldId}
                    messageId={props.inputFieldMessageId}
                    timeZoneData={{
                        timeZone: props.timeZone,
                        date: isValidDate(props.inputFieldValue) ? props.inputFieldValue : props.displayFieldValue,
                    }}
                />
                <DatePicker
                    clearableInput
                    mandatory={false}
                    locale={intl.locale}
                    alignRight
                    inputProps={{
                        id: props.inputFieldId,
                        placeholder: intl.formatMessage({
                            id: 'outboundPortal.common.chooseDatePlaceholder',
                        }),
                        disabled: props.isFormDisabled,
                    }}
                    closeOnSelect={false}
                    value={props.inputFieldValue}
                    onChange={props.onChange}
                    minWidth={400}
                    className="margin-bottom-0"
                />
            </ValidatedFormGroup>
        </div>
    );
};
