import { TransportCapacityOrder } from '../transportAssignment.types';
import { TransportCapacityOrderTransportDetails } from './details/TransportCapacityOrderTransportDetails';
import { detailSelectorText, SelectableDetails } from './TransportAssignmentSidebarBodyContent';

export const TransportCapacityOrderSidebarBodyContent = (props: { transportCapacityOrder: TransportCapacityOrder }) => {
    return (
        <div className="padding-20">
            <div className="nav nav-pills nav-pills-filled nav-justified margin-bottom-15">
                <li className="active">
                    <span>{detailSelectorText[SelectableDetails.TRANSPORT]}</span>
                </li>
                <li className="disabled pointer-events-none">
                    <span>{detailSelectorText[SelectableDetails.SHIPMENT]}</span>
                </li>
            </div>
            <TransportCapacityOrderTransportDetails transportCapacityOrder={props.transportCapacityOrder} />
        </div>
    );
};
