import { FormEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { RootState } from '../../../configuration/setup/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { DEBOUNCE_TIME } from '../debounceConfig';

export const MAX_LENGTH_INPUT = 35;

interface ToolbarSearchProps {
    getSearchTerm: (state: RootState) => string;
    setSearchTerm: ActionCreatorWithPayload<string>;
    tooltipMessageId: string;
    placeholderMessageId: string;
}

export const ToolbarSearch = (props: ToolbarSearchProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const searchValueFromStore = useAppSelector(props.getSearchTerm);
    const [searchValue, setLocalSearchValue] = useState<string>(searchValueFromStore);
    const { setSearchTerm, tooltipMessageId, placeholderMessageId } = props;

    const dispatchSearchTerm = useMemo(() => {
        return debounce((newValue: string) => {
            dispatch(setSearchTerm(newValue));
        }, DEBOUNCE_TIME);
    }, [dispatch, setSearchTerm]);

    useEffect(() => {
        return () => {
            dispatch(setSearchTerm(''));
            dispatchSearchTerm.cancel();
        };
    }, [dispatchSearchTerm, dispatch, setSearchTerm]);

    const handleChange = (newValue: string) => {
        dispatchSearchTerm(newValue);
        setLocalSearchValue(newValue);
    };

    const clearSearch = () => {
        dispatch(setSearchTerm(''));
        dispatchSearchTerm.cancel();
    };

    const handleSubmit = (e: FormEvent) => {
        // We need this to avoid a page reload on first visit
        e.preventDefault();
        dispatchSearchTerm.flush();
    };

    return (
        <OverlayTrigger
            key="tableSearch"
            placement="top"
            overlay={
                <Tooltip id="tooltip" allowOnTouch>
                    <FormattedMessage id={tooltipMessageId} />
                </Tooltip>
            }
        >
            <form onSubmit={handleSubmit} aria-label="form">
                <TableSearch
                    placeholder={intl.formatMessage({ id: placeholderMessageId })}
                    onClear={clearSearch}
                    onChange={handleChange}
                    value={searchValue}
                    maxLength={MAX_LENGTH_INPUT}
                />
            </form>
        </OverlayTrigger>
    );
};
