import { FormattedMessage } from 'react-intl';
import { ShipmentDetailsTable } from '../../../../sharedComponents/ShipmentDetailsTable';
import { Vehicle } from '../../../../sharedComponents/common.types';

type ShipmentDetailBodyProps = {
    readonly vehicle: Vehicle;
    readonly serviceKey: string | undefined;
    readonly generalInformation: string | undefined;
};

export const ShipmentDetailsExpanderBody = ({ vehicle, serviceKey, generalInformation }: ShipmentDetailBodyProps) => {
    const generalInformationRow = (
        <tr>
            <td>
                <span className="text-bold">
                    <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.details.generalInformation" />
                </span>
                <div>
                    {generalInformation ?? (
                        <span className="text-color-gray">
                            <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.details.generalInformation.notSpecified" />
                        </span>
                    )}
                </div>
            </td>
            <td />
        </tr>
    );

    return (
        <ShipmentDetailsTable
            vehicle={vehicle}
            serviceKey={serviceKey}
            displayServiceKey
            additionalRows={generalInformationRow}
        />
    );
};
