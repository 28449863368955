import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getModalFilters, transportAssignmentsSlice } from '../../../reducers/transportAssignmentsSlice';
import { isBefore, isDate } from 'date-fns';
import classNames from 'classnames';
import { isValidDate } from '../../../sharedComponents/dateHelper';
import { LabelWithTimezone } from '../../../sharedComponents/LabelWithTimezone';
import { FilterModalButton, FilterModalFooter, FilterModalTextInput } from '../../../sharedComponents/FilterModal';
import { ModalFilters } from '../../filter.types';

interface LocalModalFilters {
    startDateFilter: Date | undefined | string;
    endDateFilter: Date | undefined | string;
    pickUpCityFilter: string | undefined;
    deliveryCityFilter: string | undefined;
}

const UNSET_FILTERS: LocalModalFilters = {
    startDateFilter: undefined,
    endDateFilter: undefined,
    pickUpCityFilter: undefined,
    deliveryCityFilter: undefined,
};

interface FilterDatePickerProps {
    labelId: string;
    hasFormatError: boolean;
    hasTimeRangeOrderError: boolean;
    value: Date | undefined | string;
    onChange: (date: Moment | string | undefined) => void;
}

const FilterDatePicker = (props: FilterDatePickerProps) => {
    const intl = useIntl();

    const inputProps: React.HTMLProps<HTMLInputElement> = {
        placeholder: intl.formatMessage({
            id: 'outboundPortal.common.chooseDatePlaceholder',
        }),
    };

    return (
        <>
            <LabelWithTimezone
                messageId={props.labelId}
                timeZoneData={{ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, date: Date.now() }}
            />
            <div
                className={classNames('form-group', {
                    'has-feedback has-error margin-bottom-25': props.hasFormatError || props.hasTimeRangeOrderError,
                })}
            >
                <DatePicker
                    locale={intl.locale}
                    mandatory={false}
                    inputProps={props.value === undefined ? { ...inputProps, value: '' } : inputProps}
                    value={props.value}
                    onChange={props.onChange}
                    className="margin-bottom-0"
                />
                {props.hasFormatError && (
                    <div>
                        <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                        <span className="help-block">
                            <FormattedMessage id="outboundPortal.common.invalidDate" />
                        </span>
                    </div>
                )}
                {props.hasTimeRangeOrderError && (
                    <div>
                        <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                        <span className="help-block">
                            <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.filter.timeRangeError" />
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};

const validateDateTimeFields = (localFilters: LocalModalFilters) => {
    const hasEndDateTimeError = localFilters.endDateFilter !== undefined && !isValidDate(localFilters.endDateFilter);

    const hasStartDateTimeError =
        localFilters.startDateFilter !== undefined && !isValidDate(localFilters.startDateFilter);

    const hasTimeRangeOrderError =
        !(hasEndDateTimeError || hasStartDateTimeError) &&
        localFilters.endDateFilter !== undefined &&
        localFilters.startDateFilter !== undefined &&
        isBefore(localFilters.endDateFilter as Date, localFilters.startDateFilter as Date);

    return {
        hasEndDateTimeError,
        hasStartDateTimeError,
        hasTimeRangeOrderError,
    };
};

const TransportAssignmentFilterModalContent = ({
    localFilters,
    setLocalFilters,
    hasStartDateTimeError,
    hasEndDateTimeError,
    hasTimeRangeOrderError,
}: {
    localFilters: LocalModalFilters;
    setLocalFilters: Dispatch<SetStateAction<LocalModalFilters>>;
    hasStartDateTimeError: boolean;
    hasEndDateTimeError: boolean;
    hasTimeRangeOrderError: boolean;
}) => {
    const nextDateValue = (date: Moment | string | undefined) =>
        moment.isMoment(date) ? date.toDate() : date === '' ? undefined : date;
    const nextCityValue = (value: string | undefined) => (value === '' ? undefined : value);

    return (
        <div className="margin-bottom-20">
            <div className="text-size-14 text-bold margin-bottom-10">
                <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.filter.pickUpTimeframe" />
            </div>
            <div className="display-flex justify-content-start row">
                <div className="col-6 padding-right-5">
                    <FilterDatePicker
                        labelId="outboundPortal.transportAssignments.table.from"
                        hasFormatError={hasStartDateTimeError}
                        hasTimeRangeOrderError={false}
                        value={localFilters.startDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({ ...prevState, startDateFilter: nextDateValue(date) }))
                        }
                    />
                </div>
                <div className="col-6 padding-left-5">
                    <FilterDatePicker
                        labelId="outboundPortal.transportAssignments.table.to"
                        hasFormatError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                        value={localFilters.endDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({ ...prevState, endDateFilter: nextDateValue(date) }))
                        }
                    />
                </div>
            </div>
            <hr className="margin-top-20 margin-bottom-20" />
            <FilterModalTextInput
                headerMessageId="outboundPortal.transportAssignments.table.toolbar.filter.pickUpCity"
                placeholderMessageId="outboundOrderBook.common.table.toolbar.filter.enterLocation"
                value={localFilters.pickUpCityFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({ ...prevState, pickUpCityFilter: nextCityValue(value) }));
                }}
            />
            <div className="margin-top-20">
                <FilterModalTextInput
                    headerMessageId="outboundPortal.transportAssignments.table.toolbar.filter.deliveryCity"
                    placeholderMessageId="outboundOrderBook.common.table.toolbar.filter.enterLocation"
                    value={localFilters.deliveryCityFilter}
                    onChange={(event) => {
                        const value = event.currentTarget.value;
                        setLocalFilters((prevState) => ({ ...prevState, deliveryCityFilter: nextCityValue(value) }));
                    }}
                />
            </div>
        </div>
    );
};

const trimCityFilterInputs = (localFilters: LocalModalFilters) => ({
    pickUpCityFilter: localFilters.pickUpCityFilter?.trim(),
    deliveryCityFilter: localFilters.deliveryCityFilter?.trim(),
});

const discardInvalidDateInputs = (localFilters: LocalModalFilters) => ({
    startDateFilter: isDate(localFilters.startDateFilter) ? (localFilters.startDateFilter as Date) : undefined,
    endDateFilter: isDate(localFilters.endDateFilter) ? (localFilters.endDateFilter as Date) : undefined,
});

export const TransportAssignmentFilterModal = () => {
    const dispatch = useAppDispatch();
    const modalFilters = useAppSelector(getModalFilters);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<LocalModalFilters>(UNSET_FILTERS);

    const isFilterButtonActive =
        modalFilters.startDateFilter !== undefined ||
        modalFilters.endDateFilter !== undefined ||
        modalFilters.pickUpCityFilter !== undefined ||
        modalFilters.deliveryCityFilter !== undefined;

    const handleOnClickApply = () => {
        setLocalFilters({
            ...localFilters,
            ...trimCityFilterInputs(localFilters),
        });
        dispatch(
            transportAssignmentsSlice.actions.setModalFilters({
                ...discardInvalidDateInputs(localFilters),
                ...trimCityFilterInputs(localFilters),
            }),
        );
        setShowFilterModal(false);
    };

    const handleOnClickCancel = () => {
        setLocalFilters(modalFilters);
        setShowFilterModal(false);
    };

    const resetFilters = () => {
        setLocalFilters(UNSET_FILTERS);
    };

    const { hasStartDateTimeError, hasEndDateTimeError, hasTimeRangeOrderError } = useMemo(
        () => validateDateTimeFields(localFilters),
        [localFilters],
    );

    return (
        <>
            <FilterModalButton isFilterActive={isFilterButtonActive} setShowFilterModal={setShowFilterModal} />
            <Dialog
                show={showFilterModal}
                bsSize={Dialog.SIZE_SM}
                title={<FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.title" />}
                body={
                    <TransportAssignmentFilterModalContent
                        localFilters={localFilters}
                        setLocalFilters={setLocalFilters}
                        hasStartDateTimeError={hasStartDateTimeError}
                        hasEndDateTimeError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                    />
                }
                footer={
                    <FilterModalFooter
                        handleOnClickApply={handleOnClickApply}
                        handleOnClickCancel={handleOnClickCancel}
                        resetFilters={resetFilters}
                        isApplyButtonDisabled={hasTimeRangeOrderError || hasStartDateTimeError || hasEndDateTimeError}
                    />
                }
                onHide={handleOnClickCancel}
            />
        </>
    );
};
