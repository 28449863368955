import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import {
    getIncomingCompoundTransportOrdersModalFilter,
    incomingCompoundTransportOrdersSlice,
} from '../../../reducers/incomingCompoundTransportOrdersSlice';
import { FilterModalButton, FilterModalFooter, FilterModalTextInput } from '../../../sharedComponents/FilterModal';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';

interface LocalModalFilter {
    carrierNameFilter: string | undefined;
    locationIdFilter: string | undefined;
}

const UNSET_FILTERS: LocalModalFilter = {
    carrierNameFilter: undefined,
    locationIdFilter: undefined,
};

export const IncomingCompoundTransportOrderFilterModal = () => {
    const dispatch = useAppDispatch();
    const modalFilter = useAppSelector(getIncomingCompoundTransportOrdersModalFilter);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<LocalModalFilter>(UNSET_FILTERS);

    const isFilterButtonActive =
        modalFilter.carrierNameFilter !== undefined || modalFilter.locationIdFilter !== undefined;

    const handleOnClickApply = () => {
        setLocalFilters({
            carrierNameFilter: localFilters.carrierNameFilter?.trim(),
            locationIdFilter: localFilters.locationIdFilter?.trim(),
        });
        dispatch(
            incomingCompoundTransportOrdersSlice.actions.setModalFilters({
                carrierNameFilter: localFilters.carrierNameFilter,
                locationIdFilter: localFilters.locationIdFilter,
            }),
        );
        setShowFilterModal(false);
    };

    const handleOnClickCancel = () => {
        setLocalFilters(modalFilter);
        setShowFilterModal(false);
    };

    const resetFilters = () => {
        setLocalFilters(UNSET_FILTERS);
    };

    const content = (
        <div className="margin-bottom-20">
            <FilterModalTextInput
                headerMessageId="outboundOrderBook.compoundTransportOrder.table.toolbar.filter.locationId"
                placeholderMessageId="outboundOrderBook.common.table.toolbar.filter.enterLocation"
                value={localFilters.locationIdFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({
                        ...prevState,
                        locationIdFilter: value === '' ? undefined : value,
                    }));
                }}
            />
            <hr className="margin-top-20 margin-bottom-20" />
            <div className="margin-top-20">
                <FilterModalTextInput
                    headerMessageId="outboundOrderBook.compoundTransportOrder.table.toolbar.filter.carrier"
                    placeholderMessageId="outboundOrderBook.compoundTransportOrder.table.toolbar.filter.enterCarrier"
                    value={localFilters.carrierNameFilter}
                    onChange={(event) => {
                        const value = event.currentTarget.value;
                        setLocalFilters((prevState) => ({
                            ...prevState,
                            carrierNameFilter: value === '' ? undefined : value,
                        }));
                    }}
                />
            </div>
        </div>
    );

    return (
        <>
            <FilterModalButton isFilterActive={isFilterButtonActive} setShowFilterModal={setShowFilterModal} />
            <Dialog
                show={showFilterModal}
                bsSize={Dialog.SIZE_SM}
                title={<FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.title" />}
                body={content}
                footer={
                    <FilterModalFooter
                        handleOnClickApply={handleOnClickApply}
                        handleOnClickCancel={handleOnClickCancel}
                        resetFilters={resetFilters}
                        isApplyButtonDisabled={false}
                    />
                }
                onHide={handleOnClickCancel}
            />
        </>
    );
};
