import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { useGetIncomingCompoundTransportOrdersQuery } from '../api/compoundTransportOrderApi';
import { handleQueryError } from '../notifications/ErrorNotification';
import { IncomingCompoundTransportOrderTable } from './table/IncomingCompoundTransportOrderTable';
import { IncomingCompoundTransportOrderTableToolbar } from './table/toolbar/IncomingCompoundTransportOrderTableToolbar';
import { TransportIncomingSummary } from './TransportIncomingSummary';
import { useSetUserTriggeredFetching } from '../sharedComponents/userTriggeredFetchingHook';
import { LoadMoreButton } from '../sharedComponents/LoadMoreButton';
import { getFilters, getIncomingCompoundTransportOrdersSort } from '../reducers/incomingCompoundTransportOrdersSlice';
import { FilterableApiStatus } from '../api/outboundApi';
import { CompoundTransportOrderStatus } from './compoundTransportOrder.types';
import { IncomingCompoundTransportOrderCardsSorting } from './table/IncomingCompoundTransportOrderCardsSorting';

const POLLING_INTERVAL = 60000;
export const DEFAULT_COMPOUND_TRANSPORT_ORDER_LIMIT_SIZE = 100;

export const IncomingCompoundTransportOrders: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const filters = useAppSelector(getFilters);
    const sort = useAppSelector(getIncomingCompoundTransportOrdersSort);

    const [limit, setLimit] = useState(DEFAULT_COMPOUND_TRANSPORT_ORDER_LIMIT_SIZE);

    const {
        data: compoundTransportOrderPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetIncomingCompoundTransportOrdersQuery(
        {
            limit,
            expectedDeliveryAfter: filters.dateFilter.from,
            expectedDeliveryBefore: filters.dateFilter.to,
            carrierName: filters.modalFilter.carrierNameFilter,
            locationId: filters.modalFilter.locationIdFilter,
            sortBy: sort,
            statuses: filters.statusFilter.map(mapCompoundTransportOrderStatusToApiStatus),
            searchTerm: filters.searchTerm.length === 0 ? undefined : filters.searchTerm,
        },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [filters, sort],
    });

    const incomingCompoundTransportOrder = compoundTransportOrderPage?.items;

    if (isError) {
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (compoundTransportOrderPage === undefined || typeof incomingCompoundTransportOrder === 'undefined') {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <IncomingCompoundTransportOrderTableToolbar />
            <TransportIncomingSummary summary={compoundTransportOrderPage.summary} className="margin-bottom-25" />
            <IncomingCompoundTransportOrderCardsSorting sorting={sort} />
            <IncomingCompoundTransportOrderTable compoundTransportOrders={incomingCompoundTransportOrder} />
            {incomingCompoundTransportOrder.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_TRANSPORT_ORDER_LIMIT_SIZE)}
                    hasMoreToLoad={compoundTransportOrderPage.hasMore}
                />
            )}
        </>
    );
};

const mapCompoundTransportOrderStatusToApiStatus = (
    compoundTransportOrderStatus: CompoundTransportOrderStatus,
): FilterableApiStatus => {
    switch (compoundTransportOrderStatus) {
        case CompoundTransportOrderStatus.CANCELLED:
            return FilterableApiStatus.CANCELLATION;
        case CompoundTransportOrderStatus.CONFIRMED:
            return FilterableApiStatus.CONFIRMED;
        case CompoundTransportOrderStatus.UNCONFIRMED:
            return FilterableApiStatus.UNCONFIRMED;
        case CompoundTransportOrderStatus.LOADED:
            return FilterableApiStatus.LOADED;
        case CompoundTransportOrderStatus.UNLOADED:
            return FilterableApiStatus.UNLOADED;
    }
};
