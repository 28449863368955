import { ActivationStatus } from '../activation/activation.types';

export enum Tenant {
    VW_PROD = 'VW_PROD',
    VW_TEST = 'VW_TEST',
    VW_QA = 'VW_QA',
    LSP_TEST = 'LSP_TEST',
}

export type ServiceProvider = {
    accountId: string;
    supplier: string;
    serviceProviderName: string;
    serviceProviderId: string;
    duns: string;
    registrationDate: Date;
    activationDate?: Date;
    apiAccessRequested: boolean;
    activationStatus: ActivationStatus;
    tenants: Tenant[];
    clientId?: string;
};

export type ServiceProviderActivation = {
    accountId: string;
    activatedAt: Date;
    activationStatus: ActivationStatus;
    serviceProviderId: string;
};
