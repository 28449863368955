import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

export const CONFIRM_SPINNER_TEST_ID = 'CONFIRM_SPINNER_TEST_ID';

export const ConfirmButton = ({ showSpinner, labelId }: { showSpinner: boolean; labelId: string }) =>
    showSpinner ? (
        <Spinner data-testid={CONFIRM_SPINNER_TEST_ID} isInverse text={<FormattedMessage id={labelId} />} />
    ) : (
        <>
            <span className="rioglyph rioglyph-ok margin-right-5" />
            <FormattedMessage id={labelId} />
        </>
    );
