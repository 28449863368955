import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActivationStatus } from '../activation/activation.types';
import { usePutServiceProviderActivationMutation } from '../api/serviceProviderApi';
import { handleQueryError } from '../notifications/ErrorNotification';
import { showSuccessNotification } from '../notifications/SuccessNotification';
import { ConfirmButton } from '../sharedComponents/ConfirmButton';
import { ServiceProvider, ServiceProviderActivation } from './serviceProvider.types';

export const ActivationRequestAcceptConfirmation = ({
    serviceProvider,
    show,
    setShow,
}: {
    serviceProvider: ServiceProvider;
    show: boolean;
    setShow: (value: boolean) => void;
}) => {
    const [serviceProviderId, setServiceProviderId] = useState(serviceProvider.serviceProviderId);
    const [activateServiceProvider, activationRequest] = usePutServiceProviderActivationMutation();
    const serviceProviderName = serviceProvider.serviceProviderName;

    const handleOnClick = async () => {
        const serviceProviderActivation: ServiceProviderActivation = {
            activatedAt: new Date(),
            accountId: serviceProvider.accountId,
            activationStatus: ActivationStatus.ACTIVE,
            serviceProviderId,
        };
        setShow(false);
        const result = await activateServiceProvider({
            serviceProviderActivation,
        });
        if (result.hasOwnProperty('error')) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundOrderBook.serviceProviders.activationRequestAccept.success');
        }
    };

    return (
        <ConfirmationDialog
            show={show}
            title={<FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestAccept.title" />}
            content={
                <>
                    <div className="form-group ">
                        <label htmlFor="inputLspId">
                            <FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestAccept.serviceProviderId.label" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputLspId"
                            placeholder="Id"
                            value={serviceProviderId}
                            onChange={(event) => {
                                setServiceProviderId(event.currentTarget.value);
                            }}
                        />
                    </div>

                    <div>
                        <FormattedMessage
                            id="outboundOrderBook.serviceProviders.activationRequestAccept.text"
                            values={{
                                serviceProvider: <strong>{serviceProviderName}</strong>,
                            }}
                        />
                    </div>
                </>
            }
            bsSize={Dialog.SIZE_SM}
            onClickConfirm={handleOnClick}
            onClickCancel={() => setShow(false)}
            cancelButtonText={<FormattedMessage id="outboundOrderBook.common.button.cancel" />}
            confirmButtonText={
                <ConfirmButton
                    showSpinner={activationRequest.isLoading}
                    labelId="outboundOrderBook.common.button.accept"
                />
            }
            useOverflow
        />
    );
};
