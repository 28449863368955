import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FunctionComponent } from 'react';
import { usePostTransportCapacityOrderConfirmationMutation } from '../../api/outboundApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { getIsConfirmed } from '../TransportAssignmentUtils';
import { TransportCapacityOrder } from '../transportAssignment.types';
import { ConfirmationFormParams, TransportAssignmentConfirmationForm } from './TransportAssignmentConfirmationForm';

interface TransportCapacityOrderConfirmationFormProps {
    transportAssignment: TransportCapacityOrder;
}

export const TransportCapacityOrderConfirmationForm: FunctionComponent<TransportCapacityOrderConfirmationFormProps> = ({
    transportAssignment,
}) => {
    const [requestConfirmation, confirmationRequest] = usePostTransportCapacityOrderConfirmationMutation();

    const handleConfirmTransportCapacityOrder = async (formParams: ConfirmationFormParams) => {
        const result = await requestConfirmation(
            mapFormParamsToTransportCapacityOrderConfirmation(transportAssignment, formParams),
        );

        const hasError = result.hasOwnProperty('error');

        if (hasError) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundPortal.notification.confirmTransportAssignment.success');
        }
        return !hasError;
    };

    return (
        <TransportAssignmentConfirmationForm
            transportAssignment={transportAssignment}
            isConfirmPrimaryButton={!getIsConfirmed(transportAssignment)}
            isProcessingConfirmation={confirmationRequest.isLoading}
            handleConfirm={handleConfirmTransportCapacityOrder}
            showIncidentButton={false}
        />
    );
};

const mapFormParamsToTransportCapacityOrderConfirmation = (
    transportAssignment: TransportCapacityOrder,
    { newConfirmedPickUpDate, newConfirmedDeliveryDate, newMeansOfTransport }: ConfirmationFormParams,
) => ({
    transportCapacityOrderId: transportAssignment.id,
    confirmation: {
        changedAt: new Date(),
        pickUpAt: newConfirmedPickUpDate,
        deliverAt: newConfirmedDeliveryDate,
        meansOfTransportId: newMeansOfTransport?.id,
    },
});
