import { useGetExcelMutation } from '../../../api/outboundApi';
import { useRef, useState } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { showSuccessNotification } from '../../../notifications/SuccessNotification';
import { runInBackground } from '../../../../../configuration/setup/backgroundActions';

export const EXCEL_DOWNLOAD_BUTTON = 'excel-download-button';
export const FALL_BACK_FILE_NAME = 'transport-order-export.xlsx';

export const ExcelDownload = () => {
    const linkEl = useRef<HTMLAnchorElement>(null);
    const [getExcel, { data: fileExport, isLoading }] = useGetExcelMutation();
    const [queryIsFetching, setQueryIsFetching] = useState(false);

    if (queryIsFetching && !isLoading) {
        if (linkEl.current !== null && fileExport?.fileLink !== undefined) {
            linkEl.current.href = fileExport.fileLink;
            linkEl.current.download = fileExport.fileName ?? FALL_BACK_FILE_NAME;
            linkEl.current.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(fileExport.fileLink);
            }, 40000);
            showSuccessNotification('outboundPortal.notification.downloadExcel.success');
        }
        setQueryIsFetching(false);
    }

    const handleDownload = () => {
        setQueryIsFetching(true);
        runInBackground(getExcel);
    };

    return (
        <>
            <OverlayTrigger
                placement="bottom-end"
                overlay={
                    <Tooltip id="tooltip" allowOnTouch>
                        <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.download.hint" />
                    </Tooltip>
                }
            >
                <button
                    type="button"
                    className="btn btn-default btn-icon-only"
                    onClick={handleDownload}
                    disabled={isLoading}
                    data-testid={EXCEL_DOWNLOAD_BUTTON}
                >
                    <span
                        className={classNames('rioglyph', {
                            'rioglyph-spinner spinning': isLoading,
                            'rioglyph-download': !isLoading,
                        })}
                        aria-hidden="true"
                    />
                </button>
            </OverlayTrigger>
            <a ref={linkEl} href="" target="_blank" className="display-none" aria-hidden="true" />
        </>
    );
};
