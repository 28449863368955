import { ColumnDefinition } from '../../sharedComponents/SortedTableHeader';

export enum ServiceOrdersTableColumns {
    HIGHLIGHT = 'highlight',
    EXTERNAL_ID = 'externalId',
    STATUS = 'status',
    VIN = 'vin',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    EXECUTION_DATE = 'executionDate',
    SERVICE_CODE = 'serviceCode',
    SERVICE_NAME = 'serviceName',
    ACTION_BUTTON = 'actionButton',
}

export const serviceOrderColumnDefinition: ColumnDefinition<ServiceOrdersTableColumns>[] = [
    { column: ServiceOrdersTableColumns.HIGHLIGHT, labelId: undefined, sortable: false },
    {
        column: ServiceOrdersTableColumns.EXTERNAL_ID,
        labelId: 'outboundPortal.serviceOrders.table.identifier',
        sortable: false,
    },
    { column: ServiceOrdersTableColumns.STATUS, labelId: 'outboundOrderBook.common.heading.status', sortable: true },
    { column: ServiceOrdersTableColumns.VIN, labelId: 'outboundOrderBook.common.heading.vin', sortable: false },
    {
        column: ServiceOrdersTableColumns.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    { column: ServiceOrdersTableColumns.MODEL, labelId: 'outboundOrderBook.common.heading.model', sortable: false },
    {
        column: ServiceOrdersTableColumns.EXECUTION_DATE,
        labelId: 'outboundPortal.serviceOrders.table.executionDate',
        sortable: true,
    },
    {
        column: ServiceOrdersTableColumns.SERVICE_CODE,
        labelId: 'outboundPortal.serviceOrders.table.serviceCode',
        sortable: true,
    },
    {
        column: ServiceOrdersTableColumns.SERVICE_NAME,
        labelId: 'outboundPortal.serviceOrders.table.serviceName',
        sortable: false,
    },
    {
        column: ServiceOrdersTableColumns.ACTION_BUTTON,
        labelId: 'outboundPortal.serviceOrders.table.action',
        sortable: false,
    },
];
