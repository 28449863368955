import {
    AddressAndContact,
    MeansOfTransport,
    TransportAssignment,
    TransportAssignmentStatus,
    TransportAssignmentType,
} from '../../transportAssignment.types';
import {
    TransportAssignmentConfirmationFormState,
    TransportAssignmentData,
    ValidationResult,
} from './confirmationForm.types';
import {
    validateDeliveryDate,
    validateIncompleteDeliveryDate,
    validateIncompletePickUpDate,
    validatePickUpDate,
} from './dateValidation';

type TransportAssignmentConfirmationFormValidationResult = {
    confirmedPickUpDate: ValidationResult;
    confirmedDeliveryDate: ValidationResult;
    meansOfTransport: ValidationResult;
    unloadingAddress: ValidationResult;
    isButtonDisabled: boolean;
};

const MAX_MEANS_OF_TRANSPORT_ID_LENGTH = 35;

const noError = () => ({ error: undefined, warning: undefined });

export const isFormDisabled = (transportAssignment: TransportAssignment) =>
    transportAssignment.isFinalized || transportAssignment.status === TransportAssignmentStatus.CANCELLATION;

const mergeValidationResult = (a: ValidationResult, b: ValidationResult): ValidationResult => ({
    error: a.error ?? b.error,
    warning: a.warning ?? b.warning,
});

export const validateMeansOfTransportLength = (meansOfTransport: MeansOfTransport | undefined) =>
    meansOfTransport?.id !== undefined && MAX_MEANS_OF_TRANSPORT_ID_LENGTH < meansOfTransport?.id?.length
        ? {
              warning: undefined,
              error: 'outboundOrderBook.transportAssignments.sidebar.confirmationForm.meansOfTransport.feedbackLengthError',
          }
        : noError();

export const validateIncompleteMeansOfTransport = (
    enabled: boolean,
    meansOfTransport: MeansOfTransport | undefined,
): ValidationResult =>
    enabled && (meansOfTransport?.id === undefined || meansOfTransport?.id.length === 0)
        ? {
              error: undefined,
              warning:
                  'outboundOrderBook.transportAssignments.sidebar.confirmationForm.meansOfTransport.feedbackWarning',
          }
        : noError();

const validateIncompleteUnloadingAddress = (
    enabled: boolean,
    transportAssignment: TransportAssignment,
    unloadingAddress: AddressAndContact | undefined,
): ValidationResult =>
    enabled && transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER && unloadingAddress === undefined
        ? {
              error: undefined,
              warning:
                  'outboundPortal.transportAssignments.sidebar.assignmentInformation.unloadingAddress.feedbackWarning',
          }
        : noError();

export const validateConfirmationForm = (
    transportAssignment: TransportAssignment,
    transportAssignmentData: TransportAssignmentData,
    state: TransportAssignmentConfirmationFormState,
): TransportAssignmentConfirmationFormValidationResult => {
    const noValidationError: ValidationResult = { error: undefined, warning: undefined };
    if (isFormDisabled(transportAssignment)) {
        return {
            confirmedPickUpDate: noValidationError,
            confirmedDeliveryDate: noValidationError,
            meansOfTransport: noValidationError,
            unloadingAddress: noValidationError,
            isButtonDisabled: true,
        };
    }

    const needsCompleteInput = [
        TransportAssignmentStatus.INCOMPLETE_CONFIRMED,
        TransportAssignmentStatus.COMPLETE_CONFIRMED,
        TransportAssignmentStatus.UNCONFIRMED,
    ].includes(transportAssignment.status);

    const result = {
        confirmedPickUpDate: mergeValidationResult(
            validatePickUpDate(transportAssignmentData, state.confirmedPickUpDate),
            validateIncompletePickUpDate(needsCompleteInput, state.confirmedPickUpDate),
        ),
        confirmedDeliveryDate: mergeValidationResult(
            validateDeliveryDate(transportAssignmentData, state.confirmedDeliveryDate),
            validateIncompleteDeliveryDate(needsCompleteInput, state.confirmedDeliveryDate),
        ),
        meansOfTransport: mergeValidationResult(
            validateMeansOfTransportLength(state.meansOfTransport),
            validateIncompleteMeansOfTransport(needsCompleteInput, state.meansOfTransport),
        ),
        unloadingAddress: validateIncompleteUnloadingAddress(
            needsCompleteInput,
            transportAssignment,
            state.unloadingAddress,
        ),
    };

    const isButtonDisabled = Boolean(Object.values(result).find((r) => r?.error));

    return { ...result, isButtonDisabled };
};
