import { ServiceOrder } from '../ServiceOrders.types';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { getServiceOrderTableActionButtonConfig } from '../serviceOrderUtils';
import { serviceOrdersSlice } from '../../reducers/serviceOrdersSlice';
import { TableActionButton } from '../../sharedComponents/TableActionButton';
import { usePutArchiveServiceOrderMutation } from '../../api/serviceOrdersApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { runInBackgroundCallback } from '../../../../configuration/setup/backgroundActions';

export const ServiceOrderTableActionButton = (props: { serviceOrder: ServiceOrder }) => {
    const dispatch = useAppDispatch();
    const [archiveServiceOrder, archiveServiceOrderRequest] = usePutArchiveServiceOrderMutation();
    const actionButtonConfig = getServiceOrderTableActionButtonConfig(
        props.serviceOrder.status,
        props.serviceOrder.isFinalized,
    );

    const isLoading = archiveServiceOrderRequest.isLoading;

    const handleArchiveServiceOrder = async () => {
        const result = await archiveServiceOrder({
            serviceOrderId: props.serviceOrder.id,
        });
        if (result.hasOwnProperty('error')) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundPortal.notification.archived.success');
        }
    };

    const handleOnClick = runInBackgroundCallback(async () => {
        if (actionButtonConfig.expandSidebar) {
            dispatch(serviceOrdersSlice.actions.selectServiceOrder(props.serviceOrder));
        } else {
            await handleArchiveServiceOrder();
        }
    });

    return <TableActionButton actionButtonConfig={actionButtonConfig} isLoading={isLoading} onClick={handleOnClick} />;
};
