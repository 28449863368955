import { ColumnDefinition } from '../sharedComponents/SortedTableHeader';

export enum ServiceProviderColumn {
    ACCOUNT_ID = 'accountId',
    SERVICE_PROVIDER_NAME = 'serviceProviderName',
    SERVICE_PROVIDER_ID = 'serviceProviderId',
    DUNS = 'duns',
    REGISTRATION_DATE = 'registrationDate',
    API_ACCESS = 'apiAccessRequested',
    TENANTS = 'tenants',
    ACTIVATION_STATUS = 'activationStatus',
    ACTIVATION_DATE = 'activationDate',
    CLIENT_ID = 'clientId',
    ACTION = 'action',
}

export const serviceProviderColumnDefinition: ColumnDefinition<ServiceProviderColumn>[] = [
    {
        column: ServiceProviderColumn.SERVICE_PROVIDER_NAME,
        labelId: 'outboundOrderBook.serviceProviders.table.serviceProviderName',
        sortable: true,
    },
    {
        column: ServiceProviderColumn.SERVICE_PROVIDER_ID,
        labelId: 'outboundOrderBook.serviceProviders.table.serviceProviderId',
        sortable: true,
    },
    {
        column: ServiceProviderColumn.DUNS,
        labelId: 'outboundOrderBook.serviceProviders.table.duns',
        sortable: true,
    },
    {
        column: ServiceProviderColumn.ACCOUNT_ID,
        labelId: 'outboundOrderBook.serviceProviders.table.rioAccountId',
        sortable: false,
    },
    {
        column: ServiceProviderColumn.REGISTRATION_DATE,
        labelId: 'outboundOrderBook.serviceProviders.table.registrationDate',
        sortable: true,
    },
    {
        column: ServiceProviderColumn.API_ACCESS,
        labelId: 'outboundOrderBook.serviceProviders.table.apiAccessRequested',
        sortable: false,
    },
    {
        column: ServiceProviderColumn.TENANTS,
        labelId: 'outboundOrderBook.serviceProviders.table.tenants',
        sortable: false,
    },
    {
        column: ServiceProviderColumn.ACTIVATION_STATUS,
        labelId: 'outboundOrderBook.serviceProviders.table.activationStatus',
        sortable: true,
    },
    {
        column: ServiceProviderColumn.ACTIVATION_DATE,
        labelId: 'outboundOrderBook.serviceProviders.table.activationDate',
        sortable: true,
    },
    {
        column: ServiceProviderColumn.CLIENT_ID,
        labelId: 'outboundOrderBook.serviceProviders.table.clientId',
        sortable: false,
    },
    {
        column: ServiceProviderColumn.ACTION,
        labelId: 'outboundOrderBook.serviceProviders.table.actions',
        sortable: false,
    },
];
