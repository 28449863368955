import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';

interface FilterModalButtonProps {
    isFilterActive: boolean;
    setShowFilterModal: (value: boolean) => void;
}

export const FilterModalButton = (props: FilterModalButtonProps) => {
    const handleOnClickFilter = () => props.setShowFilterModal(true);

    return (
        <button
            className={classNames(props.isFilterActive ? 'btn btn-primary' : 'btn btn-default')}
            onClick={handleOnClickFilter}
        >
            <span
                className={classNames(
                    props.isFilterActive ? 'rioglyph rioglyph-filter-active' : 'rioglyph rioglyph-filter',
                )}
            />
            <FormattedMessage id="outboundOrderBook.common.table.toolbar.filter" />
        </button>
    );
};

interface FilterModalFooterProps {
    isApplyButtonDisabled: boolean;
    handleOnClickApply: () => void;
    handleOnClickCancel: () => void;
    resetFilters: () => void;
}

export const FilterModalFooter = (props: FilterModalFooterProps) => {
    return (
        <div className="display-flex justify-content-between">
            <button type="button" className="btn btn-primary btn-link" onClick={props.resetFilters}>
                <span className="rioglyph rioglyph-revert" aria-hidden="true" />
                <FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.reset" />
            </button>
            <div className="display-flex btn-toolbar">
                <button className="btn btn-default" onClick={props.handleOnClickCancel}>
                    <FormattedMessage id="outboundPortal.cancel" />
                </button>
                <button
                    className="btn btn-primary"
                    onClick={props.handleOnClickApply}
                    disabled={props.isApplyButtonDisabled}
                >
                    <FormattedMessage id="outboundPortal.apply" />
                </button>
            </div>
        </div>
    );
};

interface FilterModalTextInputProps {
    headerMessageId: string;
    placeholderMessageId: string;
    value: string | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FilterModalTextInput = (props: FilterModalTextInputProps) => {
    const intl = useIntl();

    return (
        <>
            <div className="text-size-14 text-bold margin-bottom-10">
                <FormattedMessage id={props.headerMessageId} />
            </div>
            <div className="input-group">
                <span className="input-group-addon">
                    <span className="rioglyph rioglyph-search" />
                </span>
                <input
                    className="form-control"
                    type="text"
                    placeholder={intl.formatMessage({ id: props.placeholderMessageId })}
                    value={props.value ?? ''}
                    onChange={props.onChange}
                    maxLength={35}
                />
            </div>
        </>
    );
};
