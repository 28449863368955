import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { TransportAssignmentType, TransportCapacityOrder } from '../transportAssignment.types';
import { getSelectedTransportAssignment, transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { SidebarHeader } from './SidebarHeader';
import { TransportCapacityOrderSidebarBodyContent } from './TransportCapacityOrderSidebarBodyContent';
import { SidebarExpander } from './SidebarExpander';
import { TransportCapacityOrderConfirmationForm } from './TransportCapacityOrderConfirmationForm';
import { usePutAcknowledgementOfExternalTransportCapacityOrderChangesMutation } from '../../api/outboundApi';
import { useEffect } from 'react';
import { runInBackground } from '../../../../configuration/setup/backgroundActions';

export const TransportCapacityOrderSidebar = () => {
    const selected = useAppSelector(getSelectedTransportAssignment);
    const capacityOrder = selected?.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER ? selected : undefined;
    return <TransportCapacityOrderSidebarInternal transportCapacityOrder={capacityOrder} key={capacityOrder?.id} />;
};
const TransportCapacityOrderSidebarInternal = ({
    transportCapacityOrder,
}: {
    transportCapacityOrder: TransportCapacityOrder | undefined;
}) => {
    const [acknowledgeChanges] = usePutAcknowledgementOfExternalTransportCapacityOrderChangesMutation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            if (transportCapacityOrder !== undefined && transportCapacityOrder.hasUnacknowledgedExternalChanges) {
                runInBackground(
                    acknowledgeChanges({
                        transportCapacityOrderId: transportCapacityOrder.id,
                        hasUnacknowledgedExternalChanges: false,
                    }),
                );
            }
        };
    }, [acknowledgeChanges, transportCapacityOrder]);

    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: 'right',
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };

    if (transportCapacityOrder === undefined) {
        return <Sidebar {...sidebarProperties} closed />;
    }

    const closeSidebar = () => {
        dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
    };

    const title = (
        <FormattedMessage
            id="outboundPortal.transportAssignments.sidebar.header.titleCapacityOrder"
            values={{
                amount: transportCapacityOrder.requestedTransportCapacity,
            }}
        />
    );
    const footerButton = (
        <div className="display-flex gap-10 flex-row-reverse">
            <button className="btn btn-default" type="button" onClick={closeSidebar}>
                <FormattedMessage id="outboundPortal.close" />
            </button>
        </div>
    );

    return (
        <Sidebar
            {...sidebarProperties}
            title={title}
            titleClassName="padding-left-10"
            closed={false}
            onClose={closeSidebar}
            footer={footerButton}
            footerClassName="margin-right-10"
        >
            <SidebarHeader
                transportAssignment={transportCapacityOrder}
                hasUnacknowledgedChanges={transportCapacityOrder.hasUnacknowledgedExternalChanges}
            />
            <SidebarExpander transportAssignment={transportCapacityOrder}>
                <TransportCapacityOrderConfirmationForm transportAssignment={transportCapacityOrder} />
            </SidebarExpander>
            <TransportCapacityOrderSidebarBodyContent transportCapacityOrder={transportCapacityOrder} />
        </Sidebar>
    );
};
