import { createAction, createReducer } from '@reduxjs/toolkit';
import { SelectOption } from '@rio-cloud/rio-uikit/types';
import { fromZonedTime } from 'date-fns-tz';
import moment, { Moment } from 'moment';
import { useMemo, useReducer } from 'react';
import { getTransportAssignmentDeliveryDates, getUnloadingAddress } from '../../TransportAssignmentUtils';
import {
    AddressAndContact,
    MeansOfTransportType,
    OrganizationIdentifierType,
    SelectableUnloadingAddress,
    TransportAssignment,
} from '../../transportAssignment.types';
import { DELETE_ID, UNLOADING_OPTIONS_TYPE_SEPARATOR } from './UnloadingAddressFormGroup';
import { TransportAssignmentConfirmationFormState, TransportAssignmentData } from './confirmationForm.types';
import { isFormDisabled, validateConfirmationForm } from './confirmationFormValidation';

const initialFormState: TransportAssignmentConfirmationFormState = {
    hasContentChanged: false,
    meansOfTransport: undefined,
    confirmedPickUpDate: undefined,
    confirmedDeliveryDate: undefined,
    unloadingAddress: { identifier: { id: '', type: OrganizationIdentifierType.BUYER } },
};

const setMeansOfTransportAction = createAction<{ id?: string; assetId?: string } | undefined>(
    'sidebar/setMeansOfTransport',
);
const setConfirmedPickUpDateAction = createAction<Date | string>('sidebar/setConfirmedPickUpDate');
const setConfirmedDeliveryDateAction = createAction<Date | string>('sidebar/setConfirmedDelivery');
const setUnloadingAddressAction = createAction<AddressAndContact | undefined>('sidebar/setUnloadingAddress');

const transportAssignmentConfirmationReducer = createReducer(initialFormState, (builder) => {
    builder.addCase(setMeansOfTransportAction, (state, action) => {
        const value = action.payload;

        // Replace white spaces when typed at the beginning or when more than one are in sequence
        const trimmedId = value?.id?.replace(/^\s+/, '')?.replace(/\s{2,}/, ' ');

        state.hasContentChanged = true;
        state.meansOfTransport = {
            id: Boolean(trimmedId) ? trimmedId : undefined,
            assetId: value?.assetId,
            type: MeansOfTransportType.TRUCK,
        };
    });
    builder.addCase(setConfirmedPickUpDateAction, (state, action) => {
        state.hasContentChanged = true;
        state.confirmedPickUpDate = action.payload;
    });
    builder.addCase(setConfirmedDeliveryDateAction, (state, action) => {
        state.hasContentChanged = true;
        state.confirmedDeliveryDate = action.payload;
    });
    builder.addCase(setUnloadingAddressAction, (state, action) => {
        state.unloadingAddress = action.payload;
        state.hasContentChanged = true;
    });
});

const setUnloadingAddress = (
    value: SelectOption,
    selectableUnloadingAddresses: SelectableUnloadingAddress[] | undefined,
) => {
    if (value.id === DELETE_ID) {
        return setUnloadingAddressAction();
    }
    const unloadingAddressId = value.id.substring(
        value.id.indexOf(UNLOADING_OPTIONS_TYPE_SEPARATOR) + UNLOADING_OPTIONS_TYPE_SEPARATOR.length,
    );
    const unloadingAddressAndContact = selectableUnloadingAddresses?.find(
        (address) => address.identifier.id === unloadingAddressId,
    );
    return setUnloadingAddressAction(unloadingAddressAndContact);
};

const setConfirmedPickUpDate = (value: Moment | string, timeZone: string) => {
    return setConfirmedPickUpDateAction(moment.isMoment(value) ? fromZonedTime(value.toDate(), timeZone) : value);
};

const setConfirmedDeliveryDate = (value: Moment | string, timeZone: string) => {
    return setConfirmedDeliveryDateAction(moment.isMoment(value) ? fromZonedTime(value.toDate(), timeZone) : value);
};

const getTransportAssignmentData = (transportAssignment: TransportAssignment): TransportAssignmentData => {
    const { requestedDeliveryDate, timeZone: deliveryTimeZone } =
        getTransportAssignmentDeliveryDates(transportAssignment);

    return {
        receivedAt: transportAssignment.receivedAt,
        requestedPickUpDate: transportAssignment.loading.requestedPickUpDate,
        pickUpTimeZone: transportAssignment.loading.timeZone,
        requestedDeliveryDate,
        deliveryTimeZone,
    };
};

const getInitialFormState = (transportAssignment: TransportAssignment): TransportAssignmentConfirmationFormState => {
    const { confirmedDeliveryDate } = getTransportAssignmentDeliveryDates(transportAssignment);
    return {
        hasContentChanged: false,
        meansOfTransport: transportAssignment.meansOfTransport,
        confirmedPickUpDate: transportAssignment.loading.confirmedPickUpDate,
        confirmedDeliveryDate,
        unloadingAddress: getUnloadingAddress(transportAssignment),
    };
};

export const useTransportAssignmentConfirmationForm = (transportAssignment: TransportAssignment) => {
    const assignmentData = useMemo(() => getTransportAssignmentData(transportAssignment), [transportAssignment]);
    const [state, dispatch] = useReducer(
        transportAssignmentConfirmationReducer,
        transportAssignment,
        getInitialFormState,
    );
    const validation = useMemo(
        () => validateConfirmationForm(transportAssignment, assignmentData, state),
        [transportAssignment, assignmentData, state],
    );

    return {
        assignmentData,
        formState: state,
        validation,
        isFormDisabled: isFormDisabled(transportAssignment),
        setMeansOfTransport: (value?: { id?: string; assetId?: string }) => dispatch(setMeansOfTransportAction(value)),
        setUnloadingAddress: (
            value: SelectOption,
            selectableUnloadingAddresses: SelectableUnloadingAddress[] | undefined,
        ) => dispatch(setUnloadingAddress(value, selectableUnloadingAddresses)),
        setConfirmedPickUpDate: (value: Moment | string) =>
            dispatch(setConfirmedPickUpDate(value, assignmentData.pickUpTimeZone)),
        setConfirmedDeliveryDate: (value: Moment | string) => {
            dispatch(setConfirmedDeliveryDate(value, assignmentData.deliveryTimeZone));
        },
    };
};
