import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage } from 'react-intl';
import { ServiceOrderTable } from './table/ServiceOrdersTable';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import {
    getSelectedServiceOrder,
    getServiceOrderCategory,
    getServiceOrderSearch,
    getServiceOrderSort,
    getServiceOrderStatusFilter,
    serviceOrdersSlice,
} from '../reducers/serviceOrdersSlice';
import { useGetServiceOrdersQuery } from '../api/serviceOrdersApi';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { ServiceOrderTableToolbar } from './table/toolbar/ServiceOrderTableToolbar';
import { useSetUserTriggeredFetching } from '../sharedComponents/userTriggeredFetchingHook';
import { LoadMoreButton } from '../sharedComponents/LoadMoreButton';
import { ServiceOrderCategory } from './ServiceOrders.types';

export const DEFAULT_SERVICE_ORDERS_LIMIT_SIZE = 100;

export const ServiceOrders = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const statusFilter = useAppSelector(getServiceOrderStatusFilter);
    const searchTerm = useAppSelector(getServiceOrderSearch);
    const category = useAppSelector(getServiceOrderCategory);
    const sort = useAppSelector(getServiceOrderSort);
    const [limit, setLimit] = useState(DEFAULT_SERVICE_ORDERS_LIMIT_SIZE);
    const {
        data: serviceOrderPage,
        isFetching,
        isLoading,
        isError,
    } = useGetServiceOrdersQuery(
        {
            statuses: statusFilter,
            searchTerm: searchTerm.length === 0 ? undefined : searchTerm,
            sortBy: sort,
            archived: category === ServiceOrderCategory.ARCHIVE,
            limit,
        },
        {
            pollingInterval: isSessionExpired ? undefined : 60000,
        },
    );
    const serviceOrders = serviceOrderPage?.items;
    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [statusFilter, searchTerm, sort, category],
    });

    const selectedServiceOrder = useAppSelector(getSelectedServiceOrder);
    const dispatch = useAppDispatch();

    if (selectedServiceOrder !== undefined) {
        if (!serviceOrders?.find((it) => it.id === selectedServiceOrder.id)) {
            dispatch(serviceOrdersSlice.actions.deselectServiceOrder());
        }
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (isError || typeof serviceOrders === 'undefined') {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <ServiceOrderTableToolbar />
            <ServiceOrderTable serviceOrders={serviceOrders} />
            {serviceOrders.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_SERVICE_ORDERS_LIMIT_SIZE)}
                    hasMoreToLoad={serviceOrderPage?.hasMore ?? false}
                />
            )}
        </>
    );
};
