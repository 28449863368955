import { Address } from './common.types';

type AddressDetailsProps = {
    readonly address: Address;
};

export const AddressDetails = ({ address }: AddressDetailsProps) => (
    <div>
        <div className="text-medium margin-bottom-10">{address.name}</div>
        <div>{address.street}</div>
        <div>
            <span className="margin-right-5">{address.postalCode}</span>
            <span>{address.city}</span>
        </div>
        <div>{address.countryCode}</div>
    </div>
);
