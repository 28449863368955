import { GetServiceOrdersQueryParams } from '../../serviceOrdersApi';
import {
    ApiServiceOrderQueryParameter,
    ApiServiceOrderStatus,
    ServiceOrderApiSort,
} from '../../types/serviceOrder/serviceOrdersApi.type';
import { ServiceOrderStatus } from '../../../serviceOrders/ServiceOrders.types';
import { ServiceOrdersTableColumns } from '../../../serviceOrders/table/ServiceOrdersTableColumns';
import { SortDirection, TableSort } from '../../../sharedComponents/SortedTableHeader';

const mapServiceOrderStatusToApiServiceOrderStatus = (status: ServiceOrderStatus): ApiServiceOrderStatus => {
    switch (status) {
        case ServiceOrderStatus.OPEN:
            return ApiServiceOrderStatus.OPEN;
        case ServiceOrderStatus.COMPLETED:
            return ApiServiceOrderStatus.COMPLETED;
        case ServiceOrderStatus.CANCELLED:
            return ApiServiceOrderStatus.CANCELLED;
    }
};

const mapServiceOrderStatusesToApiStatuses = (
    statusFilter: ServiceOrderStatus[],
): ApiServiceOrderStatus[] | undefined => {
    if (statusFilter.length <= 0) {
        return undefined;
    }
    return statusFilter.map((status) => mapServiceOrderStatusToApiServiceOrderStatus(status));
};

export const mapToServiceOrderApiSort = (sorting: TableSort<ServiceOrdersTableColumns>): ServiceOrderApiSort => {
    const sortDirection = sorting.dir;
    if (sortDirection === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case ServiceOrdersTableColumns.SERVICE_CODE:
                return ServiceOrderApiSort.TYPE_ASC;
            case ServiceOrdersTableColumns.EXECUTION_DATE:
                return ServiceOrderApiSort.COMPLETED_AT_ASC;
            case ServiceOrdersTableColumns.STATUS:
                return ServiceOrderApiSort.STATUS_ASC;
        }
    } else if (sortDirection === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case ServiceOrdersTableColumns.SERVICE_CODE:
                return ServiceOrderApiSort.TYPE_DESC;
            case ServiceOrdersTableColumns.EXECUTION_DATE:
                return ServiceOrderApiSort.COMPLETED_AT_DESC;
            case ServiceOrdersTableColumns.STATUS:
                return ServiceOrderApiSort.STATUS_DES;
        }
    }
    throw new Error('forgot to implement mapper for sorting or column');
};

export const mapToServiceOrderQueryParameters = (
    queryParameters: GetServiceOrdersQueryParams,
): ApiServiceOrderQueryParameter => ({
    statuses: queryParameters?.statuses && mapServiceOrderStatusesToApiStatuses(queryParameters.statuses),
    archived: queryParameters?.archived,
    q: queryParameters?.searchTerm,
    sort: queryParameters?.sortBy && mapToServiceOrderApiSort(queryParameters.sortBy),
    limit: queryParameters?.limit,
});
