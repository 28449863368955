import {
    getTransportAssignmentSearchTerm,
    transportAssignmentsSlice,
} from '../../../reducers/transportAssignmentsSlice';
import { ToolbarSearch } from '../../../sharedComponents/ToolbarSearch';

export const TransportOrderToolbarSearch = () => {
    return (
        <ToolbarSearch
            getSearchTerm={getTransportAssignmentSearchTerm}
            setSearchTerm={transportAssignmentsSlice.actions.setSearchTerm}
            tooltipMessageId="outboundOrderBook.transportAssignments.table.toolbar.search.tooltip"
            placeholderMessageId="outboundOrderBook.transportAssignments.table.toolbar.search.placeholder"
        />
    );
};
