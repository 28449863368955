import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { ActionButtonConfig } from './common.types';
import { useMediaQuery } from 'react-responsive';
import { MouseEvent } from 'react';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import classNames from 'classnames';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getTableViewType } from '../reducers/viewSlice';

export const TABLE_ACTION_BUTTON_SPINNER_TEST_ID = 'TABLE_ACTION_BUTTON_SPINNER_TEST_ID';
const LoadingIcon = (props: { isLoading: boolean; isInverse: boolean; classNames: string }) => {
    if (props.isLoading) {
        return (
            <span data-testid={TABLE_ACTION_BUTTON_SPINNER_TEST_ID}>
                <Spinner isInverse={props.isInverse} />
            </span>
        );
    } else {
        return <span className={classNames(props.classNames, 'aria-hidden="true"')} />;
    }
};
export const TableActionButton = (props: {
    isLoading: boolean;
    actionButtonConfig: ActionButtonConfig;
    onClick: () => void;
}) => {
    const viewType = useAppSelector(getTableViewType);
    // TODO: Decide on a specific width, the current value is arbitrary and it will depend on the other columns.
    const isViewSmall = useMediaQuery({
        query: '(max-width: 1440px)',
    });
    const isLayoutSmall = viewType !== TableViewToggles.VIEW_TYPE_SINGLE_CARD && isViewSmall;
    const preventEventPropagation = (event: MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <div onClick={preventEventPropagation}>
            <OverlayTrigger
                placement="top"
                overlay={
                    isLayoutSmall ? (
                        <Tooltip id="tooltip" className="top">
                            <FormattedMessage id={props.actionButtonConfig.labelId} />
                        </Tooltip>
                    ) : (
                        <div />
                    )
                }
            >
                <Button
                    block={!isLayoutSmall}
                    iconOnly={isLayoutSmall}
                    disabled={props.isLoading}
                    bsStyle={props.actionButtonConfig.style}
                    className={classNames(props.actionButtonConfig.className)}
                    onClick={props.onClick}
                >
                    {isLayoutSmall ? (
                        LoadingIcon({
                            isLoading: props.isLoading,
                            isInverse: props.actionButtonConfig.isSpinnerInverse,
                            classNames: props.actionButtonConfig.icon,
                        })
                    ) : (
                        <span className="gap-10 display-flex justify-content-center align-items-center">
                            {LoadingIcon({
                                isLoading: props.isLoading,
                                isInverse: props.actionButtonConfig.isSpinnerInverse,
                                classNames: props.actionButtonConfig.icon,
                            })}
                            <span className="ellipsis-1">
                                <FormattedMessage id={props.actionButtonConfig.labelId} />
                            </span>
                        </span>
                    )}
                </Button>
            </OverlayTrigger>
        </div>
    );
};
